import { Grid, Tab, Tabs, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { createTitle, formatDate, formatINR } from '../../../utils/utils';
import { StyledGrid } from '../../../utils/styledLayout';
import CustomDataTable from '../../../components/CustomDataTable';
import { Box } from '@mui/system';
import { colorGolden, colorPrimaryBlue } from '../../../config/theme';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchBorrowers,
  fetchInvestors
} from '../../../redux/reducers/adminSlice';
import AdminDashboardFilter from './AdminDashboardFilter';

const activeTab = {
  color: `${colorGolden}!important`,
  fontWeight: '700',
  fontSize: '1rem'
};
const inActiveTab = {
  color: `${colorPrimaryBlue}!important`,
  fontWeight: 'normal',
  fontSize: '1rem'
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

const headersListInvestor = [
  {
    uniquekey: 'id',
    id: 'fullname',
    label: 'Full Name',
    sortable: true
  },
  {
    id: 'amount',
    label: 'Invested Amount(₹)',
    sortable: true,
    render: row => {
      return <>{formatINR(row['amount'])}</>;
    }
  },
  {
    id: 'date',
    label: 'Date',
    sortable: true,
    render: row => {
      return <>{formatDate(row['date'], 'DD-MM-YYYY')}</>;
    }
  }
];

const headersListBorrower = [
  {
    uniquekey: 'id',
    id: 'fullname',
    label: 'Full Name',
    sortable: true
  },
  {
    id: 'amount',
    label: 'Borrowed Amount(₹)',
    sortable: true,
    render: row => {
      return <>{formatINR(row['amount'])}</>;
    }
  },
  {
    id: 'date',
    label: 'Date',
    sortable: true,
    render: row => {
      return <>{formatDate(row['date'], 'DD-MM-YYYY')}</>;
    }
  }
];

function AdminDashboard() {
  const dispatch = useDispatch();

  const {
    borrowerDetails,
    isFetchBorrowerLoading,
    investorDetails,
    isFetchInvestorLoading
  } = useSelector(state => state.admin);

  const [pageData, setPageData] = useState({
    page: 0,
    size: 5,
    fullname: '',
    fromDate: '',
    toDate: ''
  });

  const [value, setValue] = useState(0);

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  const onPageChange = page => {
    setPageData({ ...pageData, page: page });
  };
  const onRowsPerPageChange = page => {
    setPageData({ ...pageData, size: page });
  };

  const dispatchFunction = useCallback(() => {
    if (value === 0) {
      dispatch(fetchInvestors(pageData));
    } else if (value === 1) {
      dispatch(fetchBorrowers(pageData));
    }
  }, [dispatch, fetchInvestors, fetchBorrowers, pageData, value]);

  useEffect(() => {
    setPageData(prevData => ({
      ...prevData,
      page: 0,
      loanStatus: ''
    }));
  }, [value]);

  useEffect(() => {
    dispatchFunction();
  }, [pageData]);

  const onFilterSubmit = values => {
    setPageData({
      ...pageData,
      page: 0,
      fromDate: values.startDate
        ? formatDate(values.startDate, 'YYYY-MM-DD')
        : '',
      toDate: values.endDate ? formatDate(values.endDate, 'YYYY-MM-DD') : '',
      fullname: values.searchCriteria === 'fullname' ? values.searchField : ''
    });
  };

  useEffect(() => {
    document.title = createTitle('Admin Dashboard');
  }, []);

  return (
    <>
      <StyledGrid
        container
        direction="row"
        justifyContent="left"
        alignItems="left"
      >
        <Box
          sx={{
            borderRadius: 5,
            backgroundColor: 'white',
            width: '100%',
            marginTop: 3,
            paddingTop: 3,
            paddingBottom: 3,
            boxShadow: '0px 1px 17px -5px rgba(0,0,0,0.67)',
            paddingLeft: 1,
            paddingRight: 1
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="start"
            spacing={2}
            paddingRight={2}
          >
            <Grid item xs={12} sm={6} md={8} lg={8} xl={8}>
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderColor: 'divider' }}>
                  <Tabs
                    sx={{
                      paddingLeft: '1.5rem',
                      '.MuiTabs-indicator': {
                        backgroundColor: colorGolden
                      }
                    }}
                    value={value}
                    onChange={handleChange}
                    aria-label="dashboard-panel"
                  >
                    <Tab
                      sx={value === 0 ? activeTab : inActiveTab}
                      label="Investor"
                      {...a11yProps(0)}
                    />
                    <Tab
                      sx={value === 1 ? activeTab : inActiveTab}
                      label="Borrower"
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid paddingTop={3} paddingBottom={2}>
            <Grid item>
              <AdminDashboardFilter onFilterSubmit={onFilterSubmit} />
            </Grid>
          </Grid>

          <CustomTabPanel value={value} index={0}>
            <Grid container direction="row" sx={{ marginTop: 1 }}>
              <CustomDataTable
                columns={headersListInvestor}
                data={investorDetails['content']}
                headStyle={{ backgroundColor: colorGolden, color: 'black' }}
                totalCount={investorDetails['totalElements']}
                defaultRowsPerPage={5}
                defaultPage={pageData?.page}
                onRowsPerPageChange={onRowsPerPageChange}
                onPageChange={onPageChange}
                isLoading={isFetchInvestorLoading}
                isCursor={false}
              />
            </Grid>
          </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>
            <Grid container direction="row" sx={{ marginTop: 1 }}>
              <CustomDataTable
                columns={headersListBorrower}
                data={borrowerDetails['content']}
                headStyle={{ backgroundColor: colorGolden, color: 'black' }}
                totalCount={borrowerDetails['totalElements']}
                defaultRowsPerPage={5}
                defaultPage={pageData?.page}
                onRowsPerPageChange={onRowsPerPageChange}
                onPageChange={onPageChange}
                isLoading={isFetchBorrowerLoading}
                isCursor={false}
              />
            </Grid>
          </CustomTabPanel>
        </Box>
      </StyledGrid>
    </>
  );
}

export default AdminDashboard;
