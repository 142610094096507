import { useCallback, useEffect, useState } from 'react';
import { Box, Button, Grid, Typography, styled } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  createTitle,
  handleError,
  isMobile,
  isValidMobileNumber
} from '../../../utils/utils';
import CenteredLogo from '../../../components/CenteredLogo';
import { IMG_LOGIN_BG } from '../../../utils/imageUrls';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearAllNotification,
  clearOtpNotification,
  sendOtpForResetPassword,
  setResetPasswordDetails
} from '../../../redux/reducers/authSlice';
import Loader from '../../../components/Loader';
import SnackBarBox from '../../../components/SnackBarBox';
import {
  OTP_RE_SENT_TO_NEW_MOBILE_NO,
  OTP_SENT_TO_NEW_MOBILE_NO,
  RESETING_OTP,
  RE_SENDING_OTP,
  SENDING_OTP,
  VERIFYING_OTP
} from '../../../utils/messages';

import ForgotPasswordForm from './ForgotPasswordForm';
import VerifyOtpForm from './VerifyOtpForm';
import NewPasswordForm from './NewPasswordForm';
import LoginIcon from '@mui/icons-material/Login';

const CustomGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: { padding: '0px 0px' },
  [theme.breakpoints.up('md')]: { padding: '0px 60px' }
}));

function ResetPassword() {
  const { moduleName } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [redirectCount, setRedirectCount] = useState(10);

  const oneMinuteFromNow = 60;

  const [otpExpired, setOTPExpired] = useState(false);

  const {
    isSendOtpForResetPasswordLoading,
    isSendOtpForResetPasswordSuccess,
    isSendOtpForResetPasswordFailed,
    sendOtpForResetPasswordErrorContainer,
    isVerifyOtpForResetPasswordLoading,
    isVerifyOtpForResetPasswordSuccess,
    isVerifyOtpForResetPasswordFailed,
    verifyOtpForResetPasswordErrorContainer,
    isReSendOtpForResetPasswordLoading,
    isReSendOtpForResetPasswordSuccess,
    isReSendOtpForResetPasswordFailed,
    reSendOtpForResetPasswordErrorContainer,
    resetOtpDetails,
    isSubmitResetPasswordLoading,
    isSubmitResetPasswordSuccess,
    isSubmitResetPasswordFailed,
    submitResetPasswordErrorContainer
  } = useSelector(state => state.auth);

  useEffect(() => {
    document.title = createTitle('Reset Password');
    return () => dispatch(clearOtpNotification());
  }, []);

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      if (redirectCount > 0) {
        setRedirectCount(redirectCount - 1);
      }
    }, 1000);
    return () => clearInterval(countdownInterval);
  }, [redirectCount]);

  const redirectToLogin = useCallback(() => {
    dispatch(clearAllNotification());
    navigate('/login');
    return;
  }, [navigate]);

  useEffect(() => {
    if (isSubmitResetPasswordSuccess === true) {
      setRedirectCount(10);
      const timeoutId = setTimeout(redirectToLogin, 5000);
      return () => clearTimeout(timeoutId);
    }
  }, [isSubmitResetPasswordSuccess, redirectToLogin]);

  const handleManuallyRedirectToLogin = () => {
    dispatch(clearAllNotification());
    navigate('/login');
    return;
  };

  const formik = useFormik({
    initialValues: {
      mobileNo: '',
      module: moduleName
    },
    validationSchema: yup.object({
      mobileNo: yup
        .string()
        .test(
          'is-valid-mobile-number',
          'The mobile number should consist of exactly 10 digits.',
          value => isValidMobileNumber(value)
        )
    }),
    onSubmit: values => {
      let dataToBeSent = { ...values };
      if (values.mobileNo && isMobile(values.mobileNo)) {
        dataToBeSent['mobileNo'] = values.mobileNo.trim();
        dataToBeSent['module'] = moduleName;
        dispatch(
          setResetPasswordDetails({ mobileNo: values.mobileNo, otp: '' })
        );
        dispatch(sendOtpForResetPassword(dataToBeSent));
      }
    }
  });

  useEffect(() => {
    if (
      (!isSendOtpForResetPasswordLoading && isSendOtpForResetPasswordFailed) ||
      (!isReSendOtpForResetPasswordLoading && isReSendOtpForResetPasswordFailed)
    ) {
      dispatch(setResetPasswordDetails({ mobileNo: null, otp: null }));
    }
  }, [isSendOtpForResetPasswordFailed, isReSendOtpForResetPasswordFailed]);

  return (
    <>
      <Grid container sx={{ height: '100vh' }}>
        <Grid
          item
          xs={false}
          sm={7}
          md={7}
          lg={7}
          sx={{
            backgroundImage: `url(${IMG_LOGIN_BG})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: 'white',
            backgroundSize: 'contain',
            backgroundPosition: 'center'
          }}
        />
        <CustomGrid item xs={12} sm={5} md={5} lg={5} elevation={6}>
          <Box
            sx={{
              my: 15,
              mx: 4,
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <CenteredLogo />
            <Typography
              align="center"
              component="h1"
              variant="h6"
              fontWeight={'700'}
              color={'#000'}
            >
              Reset Password
            </Typography>
            <Box>
              {!resetOtpDetails?.mobileNo && !resetOtpDetails?.otp && (
                <ForgotPasswordForm formik={formik} />
              )}

              {(isSendOtpForResetPasswordSuccess ||
                isReSendOtpForResetPasswordSuccess ||
                isVerifyOtpForResetPasswordFailed) && (
                <VerifyOtpForm
                  mobileNumber={resetOtpDetails?.mobileNo}
                  oneMinuteFromNow={oneMinuteFromNow}
                  otpExpired={otpExpired}
                  setOTPExpired={setOTPExpired}
                />
              )}

              {!isVerifyOtpForResetPasswordLoading &&
                isVerifyOtpForResetPasswordSuccess &&
                !isSubmitResetPasswordSuccess && (
                  <NewPasswordForm mobileNo={resetOtpDetails?.mobileNo} />
                )}
            </Box>

            {!isSubmitResetPasswordLoading && isSubmitResetPasswordSuccess && (
              <Box style={{ textAlign: 'center' }} sx={{ mt: 2 }}>
                <Typography variant="h5" gutterBottom>
                  Password has been changed!
                </Typography>
                <Typography variant="body2" gutterBottom>
                  You will be redirected to the login page in {redirectCount}{' '}
                  seconds.
                </Typography>
                <Typography variant="body2" marginTop={2}>
                  If not auto-redirected, click below button to login page
                  manually.
                </Typography>
                <Button
                  sx={{ mt: '1rem' }}
                  variant="outlined"
                  color="primary"
                  onClick={handleManuallyRedirectToLogin}
                >
                  <LoginIcon sx={{ mr: '10px' }} /> Go to Login
                </Button>
              </Box>
            )}
          </Box>
        </CustomGrid>

        {isSendOtpForResetPasswordLoading && (
          <Loader key="send_otp" text={SENDING_OTP} />
        )}

        {isVerifyOtpForResetPasswordLoading && (
          <Loader key="verify_otp" text={VERIFYING_OTP} />
        )}

        {isReSendOtpForResetPasswordLoading && (
          <Loader key="re_send_otp" text={RE_SENDING_OTP} />
        )}

        {isSubmitResetPasswordLoading && (
          <Loader key="reset_password" text={RESETING_OTP} />
        )}

        {!isSendOtpForResetPasswordLoading &&
          isSendOtpForResetPasswordFailed && (
            <SnackBarBox
              type="error"
              message={handleError(
                sendOtpForResetPasswordErrorContainer.errorMessage
              )}
            />
          )}

        {!isReSendOtpForResetPasswordLoading &&
          isReSendOtpForResetPasswordFailed && (
            <SnackBarBox
              type="error"
              message={handleError(
                reSendOtpForResetPasswordErrorContainer.errorMessage
              )}
            />
          )}

        {!isVerifyOtpForResetPasswordLoading &&
          isVerifyOtpForResetPasswordFailed && (
            <SnackBarBox
              type="error"
              message={handleError(
                verifyOtpForResetPasswordErrorContainer.errorMessage
              )}
            />
          )}

        {!isSubmitResetPasswordLoading && isSubmitResetPasswordFailed && (
          <SnackBarBox
            type="error"
            message={handleError(
              submitResetPasswordErrorContainer.errorMessage
            )}
          />
        )}

        {!isSendOtpForResetPasswordLoading &&
          isSendOtpForResetPasswordSuccess && (
            <SnackBarBox type="success" message={OTP_SENT_TO_NEW_MOBILE_NO} />
          )}

        {!isReSendOtpForResetPasswordLoading &&
          isReSendOtpForResetPasswordSuccess && (
            <SnackBarBox
              type="success"
              message={OTP_RE_SENT_TO_NEW_MOBILE_NO}
            />
          )}
      </Grid>
    </>
  );
}

export default ResetPassword;
