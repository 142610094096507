import { ROLE_ADMIN_USER, ROLE_CREDIT_USER } from '../utils/userRoles';

export const accessibleRoutes = [
  { path: '/credit-dashboard', roles: [ROLE_CREDIT_USER] },
  { path: '/admin-dashboard', roles: [ROLE_ADMIN_USER] },
  {
    path: '/dashboard-casesummary',
    roles: [ROLE_CREDIT_USER]
  },
  { path: '/business-teams', roles: [ROLE_ADMIN_USER] },
  { path: '/branches', roles: [ROLE_ADMIN_USER] },
  { path: '/rm-view', roles: [ROLE_ADMIN_USER] },
  { path: '/admin-daviation', roles: [ROLE_ADMIN_USER] },
  { path: '/deviation-qde', roles: [ROLE_ADMIN_USER] },
  { path: '/reports-view', roles: [ROLE_ADMIN_USER] }
];
