import {
  FormControl,
  Grid,
  IconButton,
  TextField,
  Tooltip
} from '@mui/material';
import {
  FormButtonBottom,
  StyledGrid
} from '../../../../../utils/styledLayout';
import CustomDataTable from '../../../../../components/CustomDataTable';
import { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import {
  addNewBusiness,
  clearBusinessNotification,
  editNewBusiness,
  fetchBusinessUser,
  fetchToBusinessList,
  submitTransferBusiness
} from '../../../../../redux/reducers/adminSlice';
import { LoadingButton } from '@mui/lab';
import SendIcon from '@mui/icons-material/Send';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import {
  createTitle,
  enterOnlyAlphabates,
  formatDate,
  handleError,
  isArrayNotEmpty
} from '../../../../../utils/utils';
import ModalDialog from '../../../../../components/ModalDialog';
import { useFormik } from 'formik';
import * as yup from 'yup';
import BusinessUserFilter from './BusinessUserFilter';
import {
  BUSINESS_TRANSFERED_SUCCESS,
  CANCEL_BUTTON_MESSAGE,
  NEW_BUSINESS_ADD_SUCCESS,
  NEW_BUSINESS_EDIT_SUCCESS,
  SUBMIT_BUTTON_MESSAGE
} from '../../../../../utils/messages';
import GenericAutocomplete from '../../../../../components/GenericAutocomplete';
import { colorGolden } from '../../../../../config/theme';
import EditIcon from '@mui/icons-material/Edit';
import Loader from '../../../../../components/Loader';
import SnackBarBox from '../../../../../components/SnackBarBox';
import { setActiveMenu } from '../../../../../redux/reducers/menuSlice';

const formControl = {
  margin: '0.5rem auto'
};

function BusinessUser() {
  const dispatch = useDispatch();

  const findById = (array, id) => array.find(item => item.id === id);

  const findByValue = (array, value) =>
    array.find(item => item.value === value);

  const [businessList, setBusinessList] = useState([]);

  const [pageData, setPageData] = useState({
    page: 0,
    size: 5,
    businessName: '',
    fromDate: '',
    toDate: '',
    purpose: ''
  });

  const {
    toBusinessList,
    businessUserDetails,
    isFetchBusinessUserLoading,
    isAddBusinessUserLoading,
    isAddBusinessUserSuccess,
    isAddBusinessUserFailed,
    addBusinessUserErrorContainer,
    isEditBusinessUserLoading,
    isEditBusinessUserSuccess,
    isEditBusinessUserFailed,
    editBusinessUserErrorContainer,
    isTransferBusinessUserLoading,
    isTransferBusinessUserSuccess,
    isTransferBusinessUserFailed,
    transferBusinessUserErrorContainer
  } = useSelector(state => state.admin);

  useEffect(() => {
    dispatch(fetchBusinessUser(pageData));
    return () => dispatch(clearBusinessNotification());
  }, [pageData]);

  useEffect(() => {
    document.title = createTitle('Business Teams');
    dispatch(fetchToBusinessList());
    dispatch(setActiveMenu('Business Teams'));
    return () => dispatch(clearBusinessNotification());
  }, []);

  useEffect(() => {
    if (isArrayNotEmpty(toBusinessList)) {
      const keysArray = toBusinessList.map(item => item.value);
      setBusinessList(keysArray);
    }
  }, [toBusinessList]);

  const headersList = [
    {
      uniquekey: 'id',
      id: 'businessName',
      label: 'Business Name',
      sortable: true
    },
    { id: 'purpose', label: 'Purpose', sortable: true },
    {
      id: 'action',
      label: 'Action',
      sortable: true,
      render: row => {
        return (
          <>
            <Tooltip placement="top" title="Edit">
              <IconButton
                aria-label="more"
                id="long-button"
                onClick={() => handleButtonClick(row)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      }
    }
  ];

  const [selectedValues, setSelectedValues] = useState(null);

  const [isShowTransferModal, setIsShowTransferModal] = useState(false);

  const [isShowAddModal, setIsShowAddModal] = useState(false);

  const [isShowEditModal, setIsShowEditModal] = useState(false);

  const [currentSelectedBusiness, setCurrentSelectedBusiness] = useState({});

  useEffect(() => {
    if (!isAddBusinessUserLoading && isAddBusinessUserSuccess) {
      handleCloseAddBusiness();
      dispatch(fetchBusinessUser(pageData));
      dispatch(fetchToBusinessList());
    }
  }, [isAddBusinessUserSuccess]);

  useEffect(() => {
    if (!isEditBusinessUserLoading && isEditBusinessUserSuccess) {
      handleCloseEditBusiness();
      dispatch(fetchBusinessUser(pageData));
      dispatch(fetchToBusinessList());
    }
  }, [isEditBusinessUserSuccess]);

  useEffect(() => {
    if (!isTransferBusinessUserLoading && isTransferBusinessUserSuccess) {
      handleCancelTransferClick();
      dispatch(fetchBusinessUser(pageData));
    }
  }, [isTransferBusinessUserSuccess]);

  const transferBusinessFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      businessFromId: currentSelectedBusiness?.businessName || '',
      businessToId: currentSelectedBusiness?.businessToId || ''
    },
    validationSchema: yup.object({
      businessFromId: yup
        .string()
        .required('Transfer business from is required'),
      businessToId: yup.string().required('Transfer business to is required')
    }),
    onSubmit: value => {
      const newValues = {
        businessFromId: currentSelectedBusiness?.id,
        businessToId: value?.businessToId
      };
      dispatch(submitTransferBusiness(newValues));
    }
  });

  const addBusinessFormik = useFormik({
    initialValues: {
      businessName: '',
      purpose: ''
    },
    validationSchema: yup.object({
      businessName: yup.string().required('Business name is required.'),
      purpose: yup.string().required('Purpose is required.')
    }),
    onSubmit: values => {
      dispatch(addNewBusiness(values));
    }
  });

  const editBusinessFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      businessName: currentSelectedBusiness?.businessName || '',
      purpose: currentSelectedBusiness?.purpose || ''
    },
    validationSchema: yup.object({
      businessName: yup.string().required('Business name is required.'),
      purpose: yup.string().required('Purpose is required.')
    }),
    onSubmit: values => {
      dispatch(
        editNewBusiness({
          businessDetails: values,
          businessId: currentSelectedBusiness?.id
        })
      );
    }
  });

  const onPageChange = page => {
    setPageData({ ...pageData, page: page });
  };
  const onRowsPerPageChange = page => {
    setPageData({ ...pageData, size: page });
  };

  const onSelectionChange = value => {
    setSelectedValues(value);
  };

  const handleTransferClick = () => {
    const branchesDetails = businessUserDetails?.content;
    const branch = findById(branchesDetails, selectedValues[0]);
    setCurrentSelectedBusiness(branch);
    setIsShowTransferModal(!isShowTransferModal);
  };

  const handleCancelTransferClick = () => {
    setIsShowTransferModal(false);
    setCurrentSelectedBusiness({});
    transferBusinessFormik.resetForm();
  };

  const handleOpenAddBusiness = () => {
    setIsShowAddModal(!isShowAddModal);
  };

  const handleCloseAddBusiness = () => {
    setIsShowAddModal(false);
    setCurrentSelectedBusiness({});
    addBusinessFormik.resetForm();
  };
  const handleCloseEditBusiness = () => {
    setIsShowEditModal(false);
    setCurrentSelectedBusiness({});
    editBusinessFormik.resetForm();
  };

  const handleButtonClick = row => {
    setCurrentSelectedBusiness(row);
    setIsShowEditModal(!isShowEditModal);
  };

  const onFilterSubmit = value => {
    setPageData({
      ...pageData,
      page: 0,
      businessName:
        value.searchCriteria === 'businessName' ? value.searchField : '',
      fromDate: value.startDate
        ? formatDate(value.startDate, 'YYYY-MM-DD')
        : '',
      toDate: value.endDate ? formatDate(value.endDate, 'YYYY-MM-DD') : '',
      purpose: value.searchCriteria === 'purpose' ? value.searchField : ''
    });
  };

  const onAutoCompleteChange = value => {
    const rmUser = findByValue(toBusinessList, value);
    const rmKey = rmUser?.key;
    transferBusinessFormik.setFieldValue('businessToId', rmKey);
  };

  return (
    <StyledGrid
      container
      direction="row"
      justifyContent="left"
      alignItems="left"
    >
      <Box
        sx={{
          borderRadius: 5,
          backgroundColor: 'white',
          width: '100%',
          boxShadow: '0px 1px 17px -5px rgba(0,0,0,0.67)',
          paddingLeft: 1,
          paddingRight: 1
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={3}
          paddingRight={2}
          paddingTop={2}
          paddingBottom={2}
        >
          <Grid item>
            <Tooltip placement="top" title="Add Business">
              <IconButton
                onClick={handleOpenAddBusiness}
                aria-label="add"
                size="large"
                className={isShowAddModal ? '' : 'button-glow'}
              >
                <AddCircleIcon sx={{ color: colorGolden }} fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        <Grid paddingTop={2} paddingBottom={2}>
          <Grid item>
            <BusinessUserFilter onFilterSubmit={onFilterSubmit} />
          </Grid>
        </Grid>

        <Grid container direction="row" sx={{ marginTop: 1 }}>
          <CustomDataTable
            columns={headersList}
            data={businessUserDetails['content']}
            headStyle={{ backgroundColor: colorGolden, color: 'black' }}
            totalCount={businessUserDetails['totalElements']}
            defaultRowsPerPage={5}
            defaultPage={pageData?.page}
            onRowsPerPageChange={onRowsPerPageChange}
            onPageChange={onPageChange}
            isCursor={false}
            allowSelection={true}
            onSelectionChange={onSelectionChange}
            selectedRows={selectedValues}
            isLoading={isFetchBusinessUserLoading}
            checkType="radio"
          />
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          marginTop={2}
          marginBottom={2}
        >
          <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
            <LoadingButton
              endIcon={<SendIcon />}
              fullWidth
              style={{ textTransform: 'uppercase' }}
              variant="contained"
              color="primary"
              onClick={handleTransferClick}
              disabled={Boolean(!isArrayNotEmpty(selectedValues))}
            >
              Transfer
            </LoadingButton>
          </Grid>
        </Grid>

        {isShowTransferModal && (
          <ModalDialog fullwidth={true} isShowTitle={true} titleName="TRANSFER">
            <form
              autoComplete="off"
              onSubmit={transferBusinessFormik.handleSubmit}
            >
              <Grid container spacing={1} mt={2}>
                <FormControl
                  variant="outlined"
                  sx={{ ...formControl }}
                  fullWidth
                >
                  <TextField
                    label="Transfer Business From"
                    required
                    name="businessFromId"
                    type="text"
                    inputProps={{ maxLength: 60, readOnly: true }}
                    size="small"
                    value={transferBusinessFormik.values.businessFromId}
                    onBlur={transferBusinessFormik.handleBlur}
                    error={Boolean(
                      transferBusinessFormik.touched.businessFromId &&
                        transferBusinessFormik.errors.businessFromId
                    )}
                    helperText={
                      transferBusinessFormik.touched.businessFromId &&
                      transferBusinessFormik.errors.businessFromId
                    }
                  />
                </FormControl>

                <FormControl
                  variant="outlined"
                  sx={{ ...formControl }}
                  fullWidth
                >
                  <GenericAutocomplete
                    options={businessList}
                    onSelect={onAutoCompleteChange}
                    name="businessToId"
                    label="Transfer Business To"
                    onBlur={transferBusinessFormik.handleChange}
                    error={Boolean(
                      transferBusinessFormik.touched.businessToId &&
                        transferBusinessFormik.errors.businessToId
                    )}
                    helperText={
                      transferBusinessFormik.touched.businessToId &&
                      transferBusinessFormik.errors.businessToId
                    }
                  />
                </FormControl>
              </Grid>

              <FormButtonBottom sx={formControl}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <LoadingButton
                      type="button"
                      fullWidth
                      variant="outlined"
                      size="large"
                      color="primary"
                      sx={{
                        fontWeight: '600',
                        textTransform: 'uppercase'
                      }}
                      onClick={handleCancelTransferClick}
                    >
                      Cancel
                    </LoadingButton>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <LoadingButton
                      type="submit"
                      fullWidth
                      variant="contained"
                      size="large"
                      color="primary"
                      sx={{
                        fontWeight: '600',
                        textTransform: 'uppercase'
                      }}
                    >
                      Transfer
                    </LoadingButton>
                  </Grid>
                </Grid>
              </FormButtonBottom>
            </form>
          </ModalDialog>
        )}

        {isShowAddModal && (
          <ModalDialog
            fullwidth={true}
            isShowTitle={true}
            titleName="ADD BUSINESS USER"
            size="sm"
          >
            <form autoComplete="off" onSubmit={addBusinessFormik.handleSubmit}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                marginTop={1}
              >
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormControl
                    variant="outlined"
                    sx={{ ...formControl }}
                    fullWidth
                  >
                    <TextField
                      label="Business Name"
                      required
                      name="businessName"
                      type="text"
                      inputProps={{ maxLength: 60 }}
                      size="small"
                      value={addBusinessFormik.values.businessName}
                      onChange={e => {
                        addBusinessFormik.setFieldValue(
                          'businessName',
                          enterOnlyAlphabates(e.target.value)
                        );
                      }}
                      onBlur={addBusinessFormik.handleBlur}
                      error={Boolean(
                        addBusinessFormik.touched.businessName &&
                          addBusinessFormik.errors.businessName
                      )}
                      helperText={
                        addBusinessFormik.touched.businessName &&
                        addBusinessFormik.errors.businessName
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormControl
                    variant="outlined"
                    sx={{ ...formControl }}
                    fullWidth
                  >
                    <TextField
                      label="Purpose"
                      required
                      name="purpose"
                      type="text"
                      inputProps={{ maxLength: 60 }}
                      size="small"
                      value={addBusinessFormik.values.purpose}
                      onChange={e => {
                        addBusinessFormik.setFieldValue(
                          'purpose',
                          enterOnlyAlphabates(e.target.value)
                        );
                      }}
                      onBlur={addBusinessFormik.handleBlur}
                      error={Boolean(
                        addBusinessFormik.touched.purpose &&
                          addBusinessFormik.errors.purpose
                      )}
                      helperText={
                        addBusinessFormik.touched.purpose &&
                        addBusinessFormik.errors.purpose
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
                marginTop={2}
              >
                <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                  <Tooltip placement="top" title={CANCEL_BUTTON_MESSAGE}>
                    <LoadingButton
                      type="button"
                      fullWidth
                      variant="outlined"
                      size="medium"
                      color="primary"
                      sx={{
                        fontWeight: '600',
                        textTransform: 'uppercase'
                      }}
                      onClick={handleCloseAddBusiness}
                    >
                      Cancel
                    </LoadingButton>
                  </Tooltip>
                </Grid>

                <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                  <Tooltip placement="top" title={SUBMIT_BUTTON_MESSAGE}>
                    <LoadingButton
                      type="submit"
                      fullWidth
                      variant="contained"
                      size="medium"
                      color="primary"
                      sx={{
                        fontWeight: '600',
                        textTransform: 'uppercase'
                      }}
                    >
                      Submit
                    </LoadingButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </form>
          </ModalDialog>
        )}

        {isShowEditModal && (
          <ModalDialog
            fullwidth={true}
            isShowTitle={true}
            titleName="EDIT BUSINESS USER"
            size="sm"
          >
            <form autoComplete="off" onSubmit={editBusinessFormik.handleSubmit}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                marginTop={1}
              >
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormControl
                    variant="outlined"
                    sx={{ ...formControl }}
                    fullWidth
                  >
                    <TextField
                      label="Business Name"
                      required
                      name="businessName"
                      type="text"
                      inputProps={{ maxLength: 60 }}
                      size="small"
                      value={editBusinessFormik.values.businessName}
                      onChange={e => {
                        editBusinessFormik.setFieldValue(
                          'businessName',
                          enterOnlyAlphabates(e.target.value)
                        );
                      }}
                      onBlur={editBusinessFormik.handleBlur}
                      error={Boolean(
                        editBusinessFormik.touched.businessName &&
                          editBusinessFormik.errors.businessName
                      )}
                      helperText={
                        editBusinessFormik.touched.businessName &&
                        editBusinessFormik.errors.businessName
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormControl
                    variant="outlined"
                    sx={{ ...formControl }}
                    fullWidth
                  >
                    <TextField
                      label="Purpose"
                      required
                      name="purpose"
                      type="text"
                      inputProps={{ maxLength: 60 }}
                      size="small"
                      value={editBusinessFormik.values.purpose}
                      onChange={e => {
                        editBusinessFormik.setFieldValue(
                          'purpose',
                          enterOnlyAlphabates(e.target.value)
                        );
                      }}
                      onBlur={editBusinessFormik.handleBlur}
                      error={Boolean(
                        editBusinessFormik.touched.purpose &&
                          editBusinessFormik.errors.purpose
                      )}
                      helperText={
                        editBusinessFormik.touched.purpose &&
                        editBusinessFormik.errors.purpose
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
                marginTop={2}
              >
                <Grid item xs={4} sm={4} md={4} lg={3} xl={3}>
                  <Tooltip placement="top" title={CANCEL_BUTTON_MESSAGE}>
                    <LoadingButton
                      type="button"
                      fullWidth
                      variant="outlined"
                      size="medium"
                      color="primary"
                      sx={{
                        fontWeight: '600',
                        textTransform: 'uppercase'
                      }}
                      onClick={handleCloseEditBusiness}
                    >
                      Cancel
                    </LoadingButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={3} xl={3}>
                  <Tooltip placement="top" title={SUBMIT_BUTTON_MESSAGE}>
                    <LoadingButton
                      type="submit"
                      fullWidth
                      variant="contained"
                      size="medium"
                      color="primary"
                      sx={{
                        fontWeight: '600',
                        textTransform: 'uppercase'
                      }}
                    >
                      Submit
                    </LoadingButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </form>
          </ModalDialog>
        )}
      </Box>

      {isAddBusinessUserLoading && <Loader key="add_business" />}

      {!isAddBusinessUserLoading && isAddBusinessUserFailed && (
        <SnackBarBox
          type="error"
          message={handleError(addBusinessUserErrorContainer.errorMessage)}
        />
      )}

      {!isAddBusinessUserLoading && isAddBusinessUserSuccess && (
        <SnackBarBox type="success" message={NEW_BUSINESS_ADD_SUCCESS} />
      )}

      {isEditBusinessUserLoading && <Loader key="edit_business" />}

      {!isEditBusinessUserLoading && isEditBusinessUserFailed && (
        <SnackBarBox
          type="error"
          message={handleError(editBusinessUserErrorContainer.errorMessage)}
        />
      )}

      {!isEditBusinessUserLoading && isEditBusinessUserSuccess && (
        <SnackBarBox type="success" message={NEW_BUSINESS_EDIT_SUCCESS} />
      )}

      {isTransferBusinessUserLoading && <Loader key="transfer_business" />}

      {!isTransferBusinessUserLoading && isTransferBusinessUserFailed && (
        <SnackBarBox
          type="error"
          message={handleError(transferBusinessUserErrorContainer.errorMessage)}
        />
      )}

      {!isTransferBusinessUserLoading && isTransferBusinessUserSuccess && (
        <SnackBarBox type="success" message={BUSINESS_TRANSFERED_SUCCESS} />
      )}
    </StyledGrid>
  );
}

export default BusinessUser;
