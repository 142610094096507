import { useEffect, useState } from 'react';
import { lightTheme } from './config/theme';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import SplashScreen from './components/SplashScreen';
import {
  Route,
  Routes,
  BrowserRouter as Router,
  Navigate
} from 'react-router-dom';
import NotFound from './pages/NotFound';
import Maintenance from './pages/Maintenance';
import Forbidden from './pages/Forbidden';
import Login from './pages/LoginPage';
import DashboardPage from './pages/Dashboard';
import Layout from './components/Layout';
import ProtectedRoutes from './components/ProtectedRoutes';
import SessionExpired from '../src/components/SessionExpired';
import {
  ADMIN_MENUS_LIST,
  ADMIN_MODULE,
  CREDIT_DASHBOARD,
  CREDIT_MENUS_LIST,
  CREDIT_MODULE
} from './utils/constant';
import { useSelector } from 'react-redux';
import { CaseSummary } from './containers/casesummary/CaseSummary';
import ResetPassword from './containers/auth/reset/ResetPassword';
import { accessibleRoutes } from './routes/accesibleRoutes';
import BusinessUser from './containers/dashboard/admin/user_access_management/business_user/BusinessUser';
import AdminDashboard from './containers/dashboard/admin/AdminDashboard';
import Branches from './containers/dashboard/admin/user_access_management/branches/Branches';
import RMView from './containers/dashboard/admin/user_access_management/rm/RMView';
import AdminDaviationUpload from './containers/dashboard/admin/daviation/AdminDaviationUpload';
import DeviationQDE from './containers/dashboard/admin/daviation/DeviationQDE';
import ReportsView from './containers/dashboard/admin/reports/ReportsView';

function App() {
  const [showSplash, setShowSplash] = useState(
    Boolean(process.env.NODE_ENV === 'production')
  );

  const { isSessionExpired } = useSelector(state => state.session);

  useEffect(() => {
    const splashTimer = setTimeout(() => {
      setShowSplash(false);
    }, 1500);
    return () => {
      clearTimeout(splashTimer);
    };
  }, []);

  return (
    <>
      <ThemeProvider theme={lightTheme}>
        <CssBaseline />
        <Box>
          {showSplash ? (
            <SplashScreen />
          ) : (
            <>
              <Router>
                {isSessionExpired && <SessionExpired />}
                <Routes>
                  <Route
                    element={
                      <ProtectedRoutes accessibleRoutes={accessibleRoutes} />
                    }
                  >
                    <Route
                      path="/credit-dashboard"
                      element={
                        <Layout
                          title="Dashboard"
                          menulist={CREDIT_MENUS_LIST}
                          module={CREDIT_MODULE}
                        >
                          <DashboardPage module={CREDIT_DASHBOARD} />
                        </Layout>
                      }
                    />

                    <Route
                      path="/dashboard-casesummary/:id/:userId"
                      element={
                        <Layout
                          title="Credit Case Summary"
                          menulist={CREDIT_MENUS_LIST}
                          module={CREDIT_MODULE}
                        >
                          <CaseSummary type={'dashboard'} />
                        </Layout>
                      }
                    />

                    <Route
                      path="/admin-dashboard"
                      element={
                        <Layout
                          title="Dashboard"
                          menulist={ADMIN_MENUS_LIST}
                          module={ADMIN_MODULE}
                        >
                          <AdminDashboard />
                        </Layout>
                      }
                    />

                    <Route
                      path="/business-teams"
                      element={
                        <Layout
                          title="Business Teams"
                          menulist={ADMIN_MENUS_LIST}
                          module={ADMIN_MODULE}
                        >
                          <BusinessUser />
                        </Layout>
                      }
                    />

                    <Route
                      path="/branches"
                      element={
                        <Layout
                          title="Branches"
                          menulist={ADMIN_MENUS_LIST}
                          module={ADMIN_MODULE}
                        >
                          <Branches />
                        </Layout>
                      }
                    />

                    <Route
                      path="/rm-view"
                      element={
                        <Layout
                          title="RM View"
                          menulist={ADMIN_MENUS_LIST}
                          module={ADMIN_MODULE}
                        >
                          <RMView />
                        </Layout>
                      }
                    />

                    <Route
                      path="/admin-daviation"
                      element={
                        <Layout
                          title="Deviation"
                          menulist={ADMIN_MENUS_LIST}
                          module={ADMIN_MODULE}
                        >
                          <AdminDaviationUpload />
                        </Layout>
                      }
                    />

                    <Route
                      path="/deviation-qde/:id/:userId"
                      element={
                        <Layout
                          title="Deviation QDE"
                          menulist={ADMIN_MENUS_LIST}
                          module={ADMIN_MODULE}
                        >
                          <DeviationQDE type={'dashboard'} />
                        </Layout>
                      }
                    />
                    <Route
                      path="/reports-view"
                      element={
                        <Layout
                          title="Reports"
                          menulist={ADMIN_MENUS_LIST}
                          module={ADMIN_MODULE}
                        >
                          <ReportsView />
                        </Layout>
                      }
                    />
                  </Route>

                  <Route path="*" element={<NotFound />} />
                  <Route path="maintenance" element={<Maintenance />} />
                  <Route path="/forbidden" element={<Forbidden />} />
                  <Route path="login" Component={Login} />
                  <Route exact path="/" element={<Navigate to="/login" />} />
                  <Route
                    path="/reset-password/:moduleName"
                    element={<ResetPassword />}
                  />
                </Routes>
              </Router>
            </>
          )}
        </Box>
      </ThemeProvider>
    </>
  );
}

export default App;
