import { Box, Grid } from '@mui/material';
import { StyledGrid } from '../../../../utils/styledLayout';
import ReportsFilter from './ReportsFilter';
import { useEffect } from 'react';
import { createTitle, formatDate, handleError } from '../../../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearDownloadReportNotification,
  downloadReport
} from '../../../../redux/reducers/adminSlice';
import Loader from '../../../../components/Loader';
import SnackBarBox from '../../../../components/SnackBarBox';
import { REPORT_DOWNLOAD_SUCCESS } from '../../../../utils/messages';
import { setActiveMenu } from '../../../../redux/reducers/menuSlice';

function ReportsView() {
  const dispatch = useDispatch();

  const {
    isDownloadReportLoading,
    isDownloadReportSuccess,
    isDownloadReportFailed,
    downloadReportErrorContainer
  } = useSelector(state => state.admin);

  const onFilterSubmit = value => {
    const newValues = {
      type: value?.type,
      fullname: value.searchCriteria === 'fullname' ? value.searchField : '',
      fromDate: value.startDate
        ? formatDate(value.startDate, 'YYYY-MM-DD')
        : '',
      toDate: value.endDate ? formatDate(value.endDate, 'YYYY-MM-DD') : ''
    };

    dispatch(
      downloadReport({
        pageData: newValues,
        type: value?.type
      })
    );
  };

  useEffect(() => {
    document.title = createTitle('Reports');
    dispatch(setActiveMenu('Reports'));
    dispatch(clearDownloadReportNotification());
  }, []);

  return (
    <>
      <StyledGrid
        container
        direction="row"
        justifyContent="left"
        alignItems="left"
      >
        <Box
          sx={{
            borderRadius: 5,
            backgroundColor: 'white',
            width: '100%',
            marginTop: 3,
            paddingTop: 3,
            paddingBottom: 3
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="left"
            spacing={3}
            paddingRight={2}
            paddingTop={2}
            paddingBottom={2}
          >
            <Grid item>
              <ReportsFilter onFilterSubmit={onFilterSubmit} />
            </Grid>
          </Grid>
        </Box>

        {isDownloadReportLoading && <Loader key="report_download" />}

        {!isDownloadReportLoading && isDownloadReportFailed && (
          <SnackBarBox
            type="error"
            message={handleError(downloadReportErrorContainer.errorMessage)}
          />
        )}

        {!isDownloadReportLoading && isDownloadReportSuccess && (
          <SnackBarBox type="success" message={REPORT_DOWNLOAD_SUCCESS} />
        )}
      </StyledGrid>
    </>
  );
}

export default ReportsView;
