import { combineReducers } from 'redux';
import authSlice from './reducers/authSlice';
import layoutSlice from './reducers/layoutSlice';
import sessionSlice from './reducers/sessionSlice';
import menuSlice from './reducers/menuSlice';
import casesummarySlice from './reducers/casesummarySlice';
import adminSlice from './reducers/adminSlice';

const reducers = {
  auth: authSlice,
  layout: layoutSlice,
  session: sessionSlice,
  menu: menuSlice,
  caseSummary: casesummarySlice,
  admin: adminSlice
};

export default combineReducers(reducers);
