import { useDispatch, useSelector } from 'react-redux';
import {
  FormButtonBottom,
  StyledGrid
} from '../../../../../utils/styledLayout';
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip
} from '@mui/material';
import { useEffect, useState } from 'react';
import CustomDataTable from '../../../../../components/CustomDataTable';
import {
  createTitle,
  enterOnlyAlphabates,
  enterOnlyAlphabatesOrNumber,
  enterOnlyAlphabetsAndNumbers,
  enterOnlyEmail,
  enterOnlyNumbers,
  formatDate,
  formatINR,
  getFullName,
  handleError,
  isArrayNotEmpty,
  isValidEmail,
  isValidMobileNumber
} from '../../../../../utils/utils';
import {
  addNewRm,
  clearRMNotification,
  editNewRm,
  fetchRMView,
  fetchToRMList,
  submitTransferRM
} from '../../../../../redux/reducers/adminSlice';
import { colorGolden } from '../../../../../config/theme';
import EditIcon from '@mui/icons-material/Edit';
import { useFormik } from 'formik';
import * as yup from 'yup';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RMFilter from './RMFilter';
import { LoadingButton } from '@mui/lab';
import SendIcon from '@mui/icons-material/Send';
import ModalDialog from '../../../../../components/ModalDialog';
import GenericAutocomplete from '../../../../../components/GenericAutocomplete';
import {
  CANCEL_BUTTON_MESSAGE,
  NEW_RM_ADD_SUCCESS,
  NEW_RM_EDIT_SUCCESS,
  RM_TRANSFERED_SUCCESS,
  SUBMIT_BUTTON_MESSAGE
} from '../../../../../utils/messages';
import SnackBarBox from '../../../../../components/SnackBarBox';
import Loader from '../../../../../components/Loader';
import { setActiveMenu } from '../../../../../redux/reducers/menuSlice';

const formControl = {
  margin: '0.5rem auto'
};

function RMView() {
  const dispatch = useDispatch();

  const findById = (array, id) => array.find(item => item.id === id);

  const findByValue = (array, value) =>
    array.find(item => item.value === value);

  const [pageData, setPageData] = useState({
    page: 0,
    size: 5,
    fromDate: '',
    toDate: '',
    relationshipManagerId: '',
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    mobileNo: '',
    location: '',
    bankAccountNo: '',
    ifsc: ''
  });

  const headersList = [
    {
      uniquekey: 'id',
      id: 'relationshipManagerId',
      label: 'Relationship Manager ID',
      sortable: true
    },
    { id: 'firstName', label: 'First Name', sortable: true },
    {
      id: 'middleName',
      label: 'Middle Name',
      sortable: true
    },
    {
      id: 'lastName',
      label: 'Last Name',
      sortable: true
    },
    {
      id: 'email',
      label: 'Email',
      sortable: true
    },
    {
      id: 'mobileNo',
      label: 'Mobile Number',
      sortable: true
    },
    {
      id: 'location',
      label: 'Location',
      sortable: true
    },
    {
      id: 'bankAccountNo',
      label: 'Bank Account Number',
      sortable: true
    },
    {
      id: 'ifscCode',
      label: 'IFSC',
      sortable: true
    },
    {
      id: 'targetAmount',
      label: 'Target Amount',
      sortable: true,
      render: row => {
        return <>{formatINR(row['targetAmount'])}</>;
      }
    },
    {
      id: 'branchName',
      label: 'Branch Name',
      sortable: true
    },
    {
      id: 'action',
      label: 'Action',
      sortable: true,
      render: row => {
        return (
          <>
            <Tooltip placement="top" title="Edit">
              <IconButton
                aria-label="more"
                id="long-button"
                onClick={() => handleButtonClick(row)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      }
    }
  ];

  const [selectedValues, setSelectedValues] = useState(null);

  const [isShowTransferModal, setIsShowTransferModal] = useState(false);

  const [isShowAddModal, setIsShowAddModal] = useState(false);

  const [isShowEditModal, setIsShowEditModal] = useState(false);

  const [moduleList] = useState([
    {
      key: 'tbc_fixed',
      value: 'TBC FIXED'
    }
  ]);

  const [rmList, setRmList] = useState([]);

  const [currentSelectedRM, setCurrentSelectedRM] = useState({});

  const {
    RMDetails,
    isFetchRMViewLoading,
    toRmList,
    isAddRMLoading,
    isAddRMSuccess,
    isAddRMFailed,
    addRMUserErrorContainer,
    isEditRMLoading,
    isEditRMFailed,
    isEditRMSuccess,
    editRMUserErrorContainer,
    isTransferRMLoading,
    isTransferRMSuccess,
    isTransferRMFailed,
    transferRMErrorContainer
  } = useSelector(state => state.admin);

  useEffect(() => {
    if (!isAddRMLoading && isAddRMSuccess) {
      handleCloseAddRM();
      dispatch(fetchRMView(pageData));
      dispatch(fetchToRMList());
    }
  }, [isAddRMSuccess]);

  useEffect(() => {
    if (!isEditRMLoading && isEditRMSuccess) {
      handleCloseEditRM();
      dispatch(fetchRMView(pageData));
      dispatch(fetchToRMList());
    }
  }, [isEditRMSuccess]);

  useEffect(() => {
    if (!isTransferRMLoading && isTransferRMSuccess) {
      handleCancelTransferClick();
      dispatch(fetchRMView(pageData));
    }
  }, [isTransferRMSuccess]);

  useEffect(() => {
    if (isArrayNotEmpty(toRmList)) {
      const keysArray = toRmList.map(item => item.value);
      setRmList(keysArray);
    }
  }, [toRmList]);

  useEffect(() => {
    dispatch(fetchRMView(pageData));
    return () => dispatch(clearRMNotification());
  }, [pageData]);

  useEffect(() => {
    document.title = createTitle('Branches');
    dispatch(fetchToRMList());
    dispatch(setActiveMenu('Relationship Manager'));
    return () => dispatch(clearRMNotification());
  }, []);

  const handleButtonClick = row => {
    setCurrentSelectedRM(row);
    setIsShowEditModal(!isShowEditModal);
  };

  const handleOpenAddRM = () => {
    setIsShowAddModal(!isShowAddModal);
  };

  const onFilterSubmit = value => {
    setPageData({
      ...pageData,
      page: 0,
      relationshipManagerId:
        value.searchCriteria === 'relationshipManagerId'
          ? value.searchField
          : '',
      email: value.searchCriteria === 'email' ? value.searchField : '',
      mobileNo: value.searchCriteria === 'mobileNo' ? value.searchField : '',
      bankAccountNo:
        value.searchCriteria === 'bankAccountNo' ? value.searchField : '',
      ifsc: value.searchCriteria === 'ifsc' ? value.searchField : '',
      fromDate: value.startDate
        ? formatDate(value.startDate, 'YYYY-MM-DD')
        : '',
      toDate: value.endDate ? formatDate(value.endDate, 'YYYY-MM-DD') : ''
    });
  };

  const onPageChange = page => {
    setPageData({ ...pageData, page: page });
  };
  const onRowsPerPageChange = page => {
    setPageData({ ...pageData, size: page });
  };

  const onSelectionChange = value => {
    setSelectedValues(value);
  };

  const handleTransferClick = () => {
    const rmUsers = RMDetails?.content;
    const rmUser = findById(rmUsers, selectedValues[0]);
    setCurrentSelectedRM(rmUser);
    setIsShowTransferModal(!isShowTransferModal);
  };

  const transferRMFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      rmName: getFullName(
        currentSelectedRM?.firstName,
        currentSelectedRM?.middleName,
        currentSelectedRM?.lastName
      ),
      toRMId: '',
      reason: ''
    },
    validationSchema: yup.object({
      rmName: yup.string().required('RM name is required.'),
      toRMId: yup.string().required('Transfer to RM is required.'),
      reason: yup.string().required('Reason for transfer RM is required.')
    }),
    onSubmit: values => {
      const newValues = {
        fromRMId: currentSelectedRM?.id,
        toRMId: values.toRMId,
        transferReason: values?.reason.trim()
      };
      dispatch(submitTransferRM(newValues));
    }
  });

  const addRMFormik = useFormik({
    initialValues: {
      relationshipManagerId: '',
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      mobileNo: '',
      location: '',
      bankAccountNo: '',
      ifscCode: '',
      productName: 'tbc_fixed',
      targetAmount: ''
    },
    validationSchema: yup.object({
      relationshipManagerId: yup
        .string()
        .required('Relationship Manager ID is required'),
      location: yup.string().required('Location is required'),
      mobileNo: yup
        .string()
        .test(
          'is-valid-mobile-number',
          'Phone number should consist of exactly ten digits.',
          value => isValidMobileNumber(value)
        )
        .required('Mobile Number is required'),
      email: yup
        .string()
        .test(
          'is-valid-email',
          'Please provide a valid email address.',
          value => isValidEmail(value)
        )
        .required('Email ID is required'),
      firstName: yup.string().required('First Name is required'),
      middleName: yup.string().required('Middle Name is required'),
      lastName: yup.string().required('Last Name is required'),
      bankAccountNo: yup.string().required('Bank Account Number is required'),
      ifscCode: yup
        .string()
        .min(11, 'IFSC Code should be 11 characters long')
        .max(11, 'IFSC Code should be 11 characters long')
        .required('IFSC Code is required'),
      productName: yup.string().required('Product Name is required'),
      targetAmount: yup.string().required('Target amount is required')
    }),
    onSubmit: value => {
      dispatch(addNewRm(value));
    }
  });

  const editRMFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      relationshipManagerId: currentSelectedRM?.relationshipManagerId || '',
      firstName: currentSelectedRM?.firstName || '',
      middleName: currentSelectedRM?.middleName || '',
      lastName: currentSelectedRM?.lastName || '',
      email: currentSelectedRM?.email || '',
      mobileNo: currentSelectedRM?.mobileNo || '',
      location: currentSelectedRM?.location || '',
      bankAccountNo: currentSelectedRM?.bankAccountNo || '',
      ifscCode: currentSelectedRM?.ifscCode || '',
      productName: currentSelectedRM?.productName || 'tbc_fixed',
      targetAmount: currentSelectedRM?.targetAmount || ''
    },
    validationSchema: yup.object({
      relationshipManagerId: yup
        .string()
        .required('Relationship Manager ID is required'),
      location: yup.string().required('Location is required'),
      mobileNo: yup
        .string()
        .test(
          'is-valid-mobile-number',
          'Phone number should consist of exactly ten digits.',
          value => isValidMobileNumber(value)
        )
        .required('Mobile Number is required'),
      email: yup
        .string()
        .test(
          'is-valid-email',
          'Please provide a valid email address.',
          value => isValidEmail(value)
        )
        .required('Email ID is required'),
      firstName: yup.string().required('First Name is required'),
      middleName: yup.string().required('Middle Name is required'),
      lastName: yup.string().required('Last Name is required'),
      bankAccountNo: yup.string().required('Bank Account Number is required'),
      ifscCode: yup
        .string()
        .min(11, 'IFSC Code should be 11 characters long')
        .max(11, 'IFSC Code should be 11 characters long')
        .required('IFSC Code is required'),
      productName: yup.string().required('Product Name is required'),
      targetAmount: yup.string().required('Target amount is required')
    }),
    onSubmit: values => {
      dispatch(editNewRm({ rmDetails: values, rmId: currentSelectedRM?.id }));
    }
  });

  const onAutoCompleteChange = value => {
    const rmUser = findByValue(toRmList, value);
    const rmKey = rmUser?.key;
    transferRMFormik.setFieldValue('toRMId', rmKey);
  };

  const handleCancelTransferClick = () => {
    setIsShowTransferModal(false);
    setCurrentSelectedRM({});
    transferRMFormik.resetForm();
  };

  const handleCloseAddRM = () => {
    setIsShowAddModal(false);
    setCurrentSelectedRM({});
    addRMFormik.resetForm();
  };

  const handleCloseEditRM = () => {
    setIsShowEditModal(false);
    setCurrentSelectedRM({});
    editRMFormik.resetForm();
  };

  return (
    <StyledGrid
      container
      direction="row"
      justifyContent="left"
      alignItems="left"
    >
      <Box
        sx={{
          borderRadius: 5,
          backgroundColor: 'white',
          width: '100%',
          boxShadow: '0px 1px 17px -5px rgba(0,0,0,0.67)',
          paddingLeft: 1,
          paddingRight: 1
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={3}
          paddingRight={2}
          paddingTop={2}
          paddingBottom={2}
        >
          <Grid item>
            <Tooltip placement="top" title="Add RM">
              <IconButton
                onClick={handleOpenAddRM}
                aria-label="add"
                size="large"
                className={isShowAddModal ? '' : 'button-glow'}
              >
                <AddCircleIcon sx={{ color: colorGolden }} fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        <Grid paddingTop={2} paddingBottom={2}>
          <Grid item>
            <RMFilter onFilterSubmit={onFilterSubmit} />
          </Grid>
        </Grid>

        <Grid container direction="row" sx={{ marginTop: 1 }}>
          <CustomDataTable
            columns={headersList}
            data={RMDetails['content']}
            headStyle={{ backgroundColor: colorGolden, color: 'black' }}
            totalCount={RMDetails['totalElements']}
            defaultRowsPerPage={5}
            defaultPage={pageData?.page}
            onRowsPerPageChange={onRowsPerPageChange}
            onPageChange={onPageChange}
            isCursor={false}
            allowSelection={true}
            onSelectionChange={onSelectionChange}
            selectedRows={selectedValues}
            isLoading={isFetchRMViewLoading}
            checkType="radio"
          />
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          marginTop={2}
          marginBottom={2}
        >
          <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
            <LoadingButton
              endIcon={<SendIcon />}
              fullWidth
              style={{ textTransform: 'uppercase' }}
              variant="contained"
              color="primary"
              onClick={handleTransferClick}
              disabled={Boolean(!isArrayNotEmpty(selectedValues))}
            >
              Transfer
            </LoadingButton>
          </Grid>
        </Grid>

        {isShowTransferModal && (
          <ModalDialog fullwidth={true} isShowTitle={true} titleName="TRANSFER">
            <form autoComplete="off" onSubmit={transferRMFormik.handleSubmit}>
              <Grid container spacing={1} mt={2}>
                <FormControl
                  variant="outlined"
                  sx={{ ...formControl }}
                  fullWidth
                >
                  <TextField
                    label="Transfer from RM"
                    required
                    name="rmName"
                    type="text"
                    inputProps={{ maxLength: 55, readOnly: true }}
                    size="small"
                    value={transferRMFormik.values.rmName}
                    onChange={e => {
                      transferRMFormik.setFieldValue(
                        'rmName',
                        enterOnlyAlphabates(e.target.value)
                      );
                    }}
                    onBlur={transferRMFormik.handleBlur}
                    error={Boolean(
                      transferRMFormik.touched.rmName &&
                        transferRMFormik.errors.rmName
                    )}
                    helperText={
                      transferRMFormik.touched.rmName &&
                      transferRMFormik.errors.rmName
                    }
                  />
                </FormControl>

                <FormControl
                  variant="outlined"
                  sx={{ ...formControl }}
                  fullWidth
                >
                  <GenericAutocomplete
                    options={rmList}
                    onSelect={onAutoCompleteChange}
                    name="toRMId"
                    label="Transfer to RM"
                    onBlur={transferRMFormik.handleChange}
                    error={Boolean(
                      transferRMFormik.touched.toRMId &&
                        transferRMFormik.errors.toRMId
                    )}
                    helperText={
                      transferRMFormik.touched.toRMId &&
                      transferRMFormik.errors.toRMId
                    }
                  />
                </FormControl>

                <FormControl
                  variant="outlined"
                  sx={{ ...formControl }}
                  fullWidth
                >
                  <TextField
                    label="Reason for transfer of RM"
                    required
                    name="reason"
                    type="text"
                    multiline
                    rows="2"
                    inputProps={{ maxLength: 255 }}
                    size="small"
                    onChange={e =>
                      transferRMFormik.setFieldValue(
                        'reason',
                        enterOnlyAlphabates(e.target.value)
                      )
                    }
                    value={transferRMFormik.values.reason}
                    onBlur={transferRMFormik.handleBlur}
                    error={Boolean(
                      transferRMFormik.touched.reason &&
                        transferRMFormik.errors.reason
                    )}
                    helperText={
                      transferRMFormik.touched.reason &&
                      transferRMFormik.errors.reason
                    }
                  />
                </FormControl>
              </Grid>

              <FormButtonBottom sx={formControl}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <LoadingButton
                      type="button"
                      fullWidth
                      variant="outlined"
                      size="large"
                      color="primary"
                      sx={{
                        fontWeight: '600',
                        textTransform: 'uppercase'
                      }}
                      onClick={handleCancelTransferClick}
                    >
                      Cancel
                    </LoadingButton>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <LoadingButton
                      type="submit"
                      fullWidth
                      variant="contained"
                      size="large"
                      color="primary"
                      sx={{
                        fontWeight: '600',
                        textTransform: 'uppercase'
                      }}
                    >
                      Transfer
                    </LoadingButton>
                  </Grid>
                </Grid>
              </FormButtonBottom>
            </form>
          </ModalDialog>
        )}

        {isShowAddModal && (
          <ModalDialog
            fullwidth={true}
            isShowTitle={true}
            titleName="ADD RM"
            size="md"
          >
            <form autoComplete="off" onSubmit={addRMFormik.handleSubmit}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                marginTop={1}
              >
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormControl
                    variant="outlined"
                    sx={{ ...formControl }}
                    fullWidth
                  >
                    <TextField
                      label="Relationship Manager ID"
                      required
                      name="relationshipManagerId"
                      type="text"
                      inputProps={{ maxLength: 40 }}
                      size="small"
                      value={addRMFormik.values.relationshipManagerId}
                      onChange={e => {
                        addRMFormik.setFieldValue(
                          'relationshipManagerId',
                          String(
                            enterOnlyAlphabatesOrNumber(e.target.value)
                          ).toUpperCase()
                        );
                      }}
                      onBlur={addRMFormik.handleBlur}
                      error={Boolean(
                        addRMFormik.touched.relationshipManagerId &&
                          addRMFormik.errors.relationshipManagerId
                      )}
                      helperText={
                        addRMFormik.touched.relationshipManagerId &&
                        addRMFormik.errors.relationshipManagerId
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormControl
                    variant="outlined"
                    sx={{ ...formControl }}
                    fullWidth
                  >
                    <TextField
                      label="First Name"
                      required
                      name="firstName"
                      type="text"
                      inputProps={{ maxLength: 60 }}
                      size="small"
                      value={addRMFormik.values.firstName}
                      onChange={e => {
                        addRMFormik.setFieldValue(
                          'firstName',
                          enterOnlyAlphabates(e.target.value)
                        );
                      }}
                      onBlur={addRMFormik.handleBlur}
                      error={Boolean(
                        addRMFormik.touched.firstName &&
                          addRMFormik.errors.firstName
                      )}
                      helperText={
                        addRMFormik.touched.firstName &&
                        addRMFormik.errors.firstName
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormControl
                    variant="outlined"
                    sx={{ ...formControl }}
                    fullWidth
                  >
                    <TextField
                      label="Middle Name"
                      required
                      name="middleName"
                      inputProps={{ maxLength: 60 }}
                      size="small"
                      value={addRMFormik.values.middleName}
                      onChange={e => {
                        addRMFormik.setFieldValue(
                          'middleName',
                          enterOnlyAlphabates(e.target.value)
                        );
                      }}
                      onBlur={addRMFormik.handleBlur}
                      error={Boolean(
                        addRMFormik.touched.middleName &&
                          addRMFormik.errors.middleName
                      )}
                      helperText={
                        addRMFormik.touched.middleName &&
                        addRMFormik.errors.middleName
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormControl
                    variant="outlined"
                    sx={{ ...formControl }}
                    fullWidth
                  >
                    <TextField
                      label="Last Name"
                      required
                      name="lastName"
                      inputProps={{ maxLength: 60 }}
                      size="small"
                      value={addRMFormik.values.lastName}
                      onChange={e => {
                        addRMFormik.setFieldValue(
                          'lastName',
                          enterOnlyAlphabates(e.target.value)
                        );
                      }}
                      onBlur={addRMFormik.handleBlur}
                      error={Boolean(
                        addRMFormik.touched.lastName &&
                          addRMFormik.errors.lastName
                      )}
                      helperText={
                        addRMFormik.touched.lastName &&
                        addRMFormik.errors.lastName
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="left"
                alignItems="left"
                spacing={1}
              >
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormControl
                    variant="outlined"
                    sx={{ ...formControl }}
                    fullWidth
                  >
                    <TextField
                      label="Email ID"
                      required
                      name="email"
                      type="email"
                      inputProps={{ maxLength: 60 }}
                      size="small"
                      value={addRMFormik.values.email}
                      onChange={e => {
                        addRMFormik.setFieldValue(
                          'email',
                          enterOnlyEmail(e.target.value)
                        );
                      }}
                      onBlur={addRMFormik.handleBlur}
                      error={Boolean(
                        addRMFormik.touched.email && addRMFormik.errors.email
                      )}
                      helperText={
                        addRMFormik.touched.email && addRMFormik.errors.email
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormControl
                    variant="outlined"
                    sx={{ ...formControl }}
                    fullWidth
                  >
                    <TextField
                      label="Mobile No"
                      required
                      name="mobileNo"
                      type="tel"
                      inputProps={{ maxLength: 10 }}
                      size="small"
                      value={addRMFormik.values.mobileNo}
                      onChange={e => {
                        addRMFormik.setFieldValue(
                          'mobileNo',
                          enterOnlyNumbers(e.target.value)
                        );
                      }}
                      onBlur={addRMFormik.handleBlur}
                      error={Boolean(
                        addRMFormik.touched.mobileNo &&
                          addRMFormik.errors.mobileNo
                      )}
                      helperText={
                        addRMFormik.touched.mobileNo &&
                        addRMFormik.errors.mobileNo
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="left"
                alignItems="left"
                spacing={1}
              >
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormControl
                    variant="outlined"
                    sx={{ ...formControl }}
                    fullWidth
                  >
                    <TextField
                      label="Location"
                      required
                      name="location"
                      inputProps={{ maxLength: 60 }}
                      size="small"
                      value={addRMFormik.values.location}
                      onChange={e => {
                        addRMFormik.setFieldValue(
                          'location',
                          enterOnlyAlphabates(e.target.value)
                        );
                      }}
                      onBlur={addRMFormik.handleBlur}
                      error={Boolean(
                        addRMFormik.touched.location &&
                          addRMFormik.errors.location
                      )}
                      helperText={
                        addRMFormik.touched.location &&
                        addRMFormik.errors.location
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormControl
                    variant="outlined"
                    sx={{ ...formControl }}
                    fullWidth
                  >
                    <TextField
                      label="Bank Account Number"
                      required
                      name="bankAccountNo"
                      inputProps={{ maxLength: 20 }}
                      size="small"
                      value={addRMFormik.values.bankAccountNo}
                      onChange={e => {
                        addRMFormik.setFieldValue(
                          'bankAccountNo',
                          enterOnlyNumbers(e.target.value)
                        );
                      }}
                      onBlur={addRMFormik.handleBlur}
                      error={Boolean(
                        addRMFormik.touched.bankAccountNo &&
                          addRMFormik.errors.bankAccountNo
                      )}
                      helperText={
                        addRMFormik.touched.bankAccountNo &&
                        addRMFormik.errors.bankAccountNo
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="left"
                alignItems="left"
                spacing={1}
              >
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormControl
                    variant="outlined"
                    sx={{ ...formControl }}
                    fullWidth
                  >
                    <TextField
                      label="IFSC Code"
                      required
                      name="ifscCode"
                      inputProps={{ maxLength: 11 }}
                      size="small"
                      value={addRMFormik.values.ifscCode}
                      onChange={e => {
                        addRMFormik.setFieldValue(
                          'ifscCode',
                          String(
                            enterOnlyAlphabetsAndNumbers(e.target.value)
                          ).toUpperCase()
                        );
                      }}
                      onBlur={addRMFormik.handleBlur}
                      error={Boolean(
                        addRMFormik.touched.ifscCode &&
                          addRMFormik.errors.ifscCode
                      )}
                      helperText={
                        addRMFormik.touched.ifscCode &&
                        addRMFormik.errors.ifscCode
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormControl sx={{ ...formControl }} fullWidth>
                    <InputLabel id="select-product" required>
                      Product Name
                    </InputLabel>
                    <Select
                      displayEmpty
                      required
                      labelId="select-product"
                      id="select-product"
                      label="Product Name"
                      size="small"
                      value={addRMFormik.values.productName}
                      onChange={addRMFormik.handleChange}
                      name="productName"
                      sx={{ textAlign: 'left' }}
                      startAdornment={
                        <span
                          style={{
                            padding: '0.5rem',
                            cursor: 'pointer',
                            display: 'inline-flex',
                            alignItems: 'center',
                            color: colorGolden
                          }}
                        ></span>
                      }
                    >
                      <MenuItem value="" selected>
                        <em style={{ fontStyle: 'normal' }}>Select Product</em>
                      </MenuItem>

                      {isArrayNotEmpty(moduleList) &&
                        moduleList.map((type, _idx) => {
                          return (
                            <MenuItem
                              key={`${_idx}_${type?.key}`}
                              value={type?.key}
                            >
                              <em style={{ fontStyle: 'normal' }}>
                                {type?.value}
                              </em>
                            </MenuItem>
                          );
                        })}
                    </Select>
                    {addRMFormik.touched.productName &&
                      addRMFormik.errors.productName && (
                        <FormHelperText sx={{ textAlign: 'start' }}>
                          {addRMFormik.errors.productName}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="left"
                alignItems="left"
                spacing={1}
              >
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormControl
                    variant="outlined"
                    sx={{ ...formControl }}
                    fullWidth
                  >
                    <TextField
                      label="Target Amount"
                      required
                      name="targetAmount"
                      type="tel"
                      inputProps={{ maxLength: 10 }}
                      size="small"
                      value={addRMFormik.values.targetAmount}
                      onChange={e => {
                        addRMFormik.setFieldValue(
                          'targetAmount',
                          enterOnlyNumbers(e.target.value)
                        );
                      }}
                      onBlur={addRMFormik.handleBlur}
                      error={Boolean(
                        addRMFormik.touched.targetAmount &&
                          addRMFormik.errors.targetAmount
                      )}
                      helperText={
                        addRMFormik.touched.targetAmount &&
                        addRMFormik.errors.targetAmount
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
                marginTop={2}
              >
                <Grid item xs={4} sm={4} md={4} lg={3} xl={3}>
                  <Tooltip placement="top" title={CANCEL_BUTTON_MESSAGE}>
                    <LoadingButton
                      type="button"
                      fullWidth
                      variant="outlined"
                      size="medium"
                      color="primary"
                      sx={{
                        fontWeight: '600',
                        textTransform: 'uppercase'
                      }}
                      onClick={handleCloseAddRM}
                    >
                      Cancel
                    </LoadingButton>
                  </Tooltip>
                </Grid>

                <Grid item xs={4} sm={4} md={4} lg={3} xl={3}>
                  <Tooltip placement="top" title={SUBMIT_BUTTON_MESSAGE}>
                    <LoadingButton
                      type="submit"
                      fullWidth
                      variant="contained"
                      size="medium"
                      color="primary"
                      sx={{
                        fontWeight: '600',
                        textTransform: 'uppercase'
                      }}
                    >
                      Submit
                    </LoadingButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </form>
          </ModalDialog>
        )}

        {isShowEditModal && (
          <ModalDialog
            fullwidth={true}
            isShowTitle={true}
            titleName="EDIT RM"
            size="md"
          >
            <form autoComplete="off" onSubmit={editRMFormik.handleSubmit}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                marginTop={1}
              >
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormControl
                    variant="outlined"
                    sx={{ ...formControl }}
                    fullWidth
                  >
                    <TextField
                      label="Relationship Manager ID"
                      required
                      name="relationshipManagerId"
                      type="text"
                      inputProps={{ maxLength: 40 }}
                      size="small"
                      value={editRMFormik.values.relationshipManagerId}
                      onChange={e => {
                        editRMFormik.setFieldValue(
                          'relationshipManagerId',
                          String(
                            enterOnlyAlphabatesOrNumber(e.target.value)
                          ).toUpperCase()
                        );
                      }}
                      onBlur={editRMFormik.handleBlur}
                      error={Boolean(
                        editRMFormik.touched.relationshipManagerId &&
                          editRMFormik.errors.relationshipManagerId
                      )}
                      helperText={
                        editRMFormik.touched.relationshipManagerId &&
                        editRMFormik.errors.relationshipManagerId
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormControl
                    variant="outlined"
                    sx={{ ...formControl }}
                    fullWidth
                  >
                    <TextField
                      label="First Name"
                      required
                      name="firstName"
                      type="text"
                      inputProps={{ maxLength: 60 }}
                      size="small"
                      value={editRMFormik.values.firstName}
                      onChange={e => {
                        editRMFormik.setFieldValue(
                          'firstName',
                          enterOnlyAlphabates(e.target.value)
                        );
                      }}
                      onBlur={editRMFormik.handleBlur}
                      error={Boolean(
                        editRMFormik.touched.firstName &&
                          editRMFormik.errors.firstName
                      )}
                      helperText={
                        editRMFormik.touched.firstName &&
                        editRMFormik.errors.firstName
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormControl
                    variant="outlined"
                    sx={{ ...formControl }}
                    fullWidth
                  >
                    <TextField
                      label="Middle Name"
                      required
                      name="middleName"
                      inputProps={{ maxLength: 60 }}
                      size="small"
                      value={editRMFormik.values.middleName}
                      onChange={e => {
                        editRMFormik.setFieldValue(
                          'middleName',
                          enterOnlyAlphabates(e.target.value)
                        );
                      }}
                      onBlur={editRMFormik.handleBlur}
                      error={Boolean(
                        editRMFormik.touched.middleName &&
                          editRMFormik.errors.middleName
                      )}
                      helperText={
                        editRMFormik.touched.middleName &&
                        editRMFormik.errors.middleName
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormControl
                    variant="outlined"
                    sx={{ ...formControl }}
                    fullWidth
                  >
                    <TextField
                      label="Last Name"
                      required
                      name="lastName"
                      inputProps={{ maxLength: 60 }}
                      size="small"
                      value={editRMFormik.values.lastName}
                      onChange={e => {
                        editRMFormik.setFieldValue(
                          'lastName',
                          enterOnlyAlphabates(e.target.value)
                        );
                      }}
                      onBlur={editRMFormik.handleBlur}
                      error={Boolean(
                        editRMFormik.touched.lastName &&
                          editRMFormik.errors.lastName
                      )}
                      helperText={
                        editRMFormik.touched.lastName &&
                        editRMFormik.errors.lastName
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="left"
                alignItems="left"
                spacing={1}
              >
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormControl
                    variant="outlined"
                    sx={{ ...formControl }}
                    fullWidth
                  >
                    <TextField
                      label="Email ID"
                      required
                      name="email"
                      type="email"
                      inputProps={{ maxLength: 60 }}
                      size="small"
                      value={editRMFormik.values.email}
                      onChange={e => {
                        editRMFormik.setFieldValue(
                          'email',
                          enterOnlyEmail(e.target.value)
                        );
                      }}
                      onBlur={editRMFormik.handleBlur}
                      error={Boolean(
                        editRMFormik.touched.email && editRMFormik.errors.email
                      )}
                      helperText={
                        editRMFormik.touched.email && editRMFormik.errors.email
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormControl
                    variant="outlined"
                    sx={{ ...formControl }}
                    fullWidth
                  >
                    <TextField
                      label="Mobile No"
                      required
                      name="mobileNo"
                      type="tel"
                      inputProps={{ maxLength: 10 }}
                      size="small"
                      value={editRMFormik.values.mobileNo}
                      onChange={e => {
                        editRMFormik.setFieldValue(
                          'mobileNo',
                          enterOnlyNumbers(e.target.value)
                        );
                      }}
                      onBlur={editRMFormik.handleBlur}
                      error={Boolean(
                        editRMFormik.touched.mobileNo &&
                          editRMFormik.errors.mobileNo
                      )}
                      helperText={
                        editRMFormik.touched.mobileNo &&
                        editRMFormik.errors.mobileNo
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="left"
                alignItems="left"
                spacing={1}
              >
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormControl
                    variant="outlined"
                    sx={{ ...formControl }}
                    fullWidth
                  >
                    <TextField
                      label="Location"
                      required
                      name="location"
                      inputProps={{ maxLength: 60 }}
                      size="small"
                      value={editRMFormik.values.location}
                      onChange={e => {
                        editRMFormik.setFieldValue(
                          'location',
                          enterOnlyAlphabates(e.target.value)
                        );
                      }}
                      onBlur={editRMFormik.handleBlur}
                      error={Boolean(
                        editRMFormik.touched.location &&
                          editRMFormik.errors.location
                      )}
                      helperText={
                        editRMFormik.touched.location &&
                        editRMFormik.errors.location
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormControl
                    variant="outlined"
                    sx={{ ...formControl }}
                    fullWidth
                  >
                    <TextField
                      label="Bank Account Number"
                      required
                      name="bankAccountNo"
                      inputProps={{ maxLength: 60 }}
                      size="small"
                      value={editRMFormik.values.bankAccountNo}
                      onChange={e => {
                        editRMFormik.setFieldValue(
                          'bankAccountNo',
                          enterOnlyNumbers(e.target.value)
                        );
                      }}
                      onBlur={editRMFormik.handleBlur}
                      error={Boolean(
                        editRMFormik.touched.bankAccountNo &&
                          editRMFormik.errors.bankAccountNo
                      )}
                      helperText={
                        editRMFormik.touched.bankAccountNo &&
                        editRMFormik.errors.bankAccountNo
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="left"
                alignItems="left"
                spacing={1}
              >
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormControl
                    variant="outlined"
                    sx={{ ...formControl }}
                    fullWidth
                  >
                    <TextField
                      label="IFSC Code"
                      required
                      name="ifscCode"
                      inputProps={{ maxLength: 11 }}
                      size="small"
                      value={editRMFormik.values.ifscCode}
                      onChange={e => {
                        editRMFormik.setFieldValue(
                          'ifscCode',
                          String(
                            enterOnlyAlphabetsAndNumbers(e.target.value)
                          ).toUpperCase()
                        );
                      }}
                      onBlur={editRMFormik.handleBlur}
                      error={Boolean(
                        editRMFormik.touched.ifscCode &&
                          editRMFormik.errors.ifscCode
                      )}
                      helperText={
                        editRMFormik.touched.ifscCode &&
                        editRMFormik.errors.ifscCode
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormControl sx={{ ...formControl }} fullWidth>
                    <InputLabel id="select-product" required>
                      Product Name
                    </InputLabel>
                    <Select
                      displayEmpty
                      required
                      labelId="select-product"
                      id="select-product"
                      label="Product Name"
                      size="small"
                      value={editRMFormik.values.productName}
                      onChange={editRMFormik.handleChange}
                      name="productName"
                      sx={{ textAlign: 'left' }}
                      startAdornment={
                        <span
                          style={{
                            padding: '0.5rem',
                            cursor: 'pointer',
                            display: 'inline-flex',
                            alignItems: 'center',
                            color: colorGolden
                          }}
                        ></span>
                      }
                    >
                      <MenuItem value="" selected>
                        <em style={{ fontStyle: 'normal' }}>Select Product</em>
                      </MenuItem>

                      {isArrayNotEmpty(moduleList) &&
                        moduleList.map((type, _idx) => {
                          return (
                            <MenuItem
                              key={`${_idx}_${type?.key}`}
                              value={type?.key}
                            >
                              <em style={{ fontStyle: 'normal' }}>
                                {type?.value}
                              </em>
                            </MenuItem>
                          );
                        })}
                    </Select>
                    {editRMFormik.touched.productName &&
                      editRMFormik.errors.productName && (
                        <FormHelperText sx={{ textAlign: 'start' }}>
                          {editRMFormik.errors.productName}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="left"
                alignItems="left"
                spacing={1}
              >
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormControl
                    variant="outlined"
                    sx={{ ...formControl }}
                    fullWidth
                  >
                    <TextField
                      label="Target Amount"
                      required
                      name="targetAmount"
                      type="tel"
                      inputProps={{ maxLength: 10 }}
                      size="small"
                      value={editRMFormik.values.targetAmount}
                      onChange={e => {
                        editRMFormik.setFieldValue(
                          'targetAmount',
                          enterOnlyNumbers(e.target.value)
                        );
                      }}
                      onBlur={editRMFormik.handleBlur}
                      error={Boolean(
                        editRMFormik.touched.targetAmount &&
                          editRMFormik.errors.targetAmount
                      )}
                      helperText={
                        editRMFormik.touched.targetAmount &&
                        editRMFormik.errors.targetAmount
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
                marginTop={2}
              >
                <Grid item xs={4} sm={4} md={4} lg={3} xl={3}>
                  <Tooltip placement="top" title={CANCEL_BUTTON_MESSAGE}>
                    <LoadingButton
                      type="button"
                      fullWidth
                      variant="outlined"
                      size="medium"
                      color="primary"
                      sx={{
                        fontWeight: '600',
                        textTransform: 'uppercase'
                      }}
                      onClick={handleCloseEditRM}
                    >
                      Cancel
                    </LoadingButton>
                  </Tooltip>
                </Grid>

                <Grid item xs={4} sm={4} md={4} lg={3} xl={3}>
                  <Tooltip placement="top" title={SUBMIT_BUTTON_MESSAGE}>
                    <LoadingButton
                      type="submit"
                      fullWidth
                      variant="contained"
                      size="medium"
                      color="primary"
                      sx={{
                        fontWeight: '600',
                        textTransform: 'uppercase'
                      }}
                    >
                      Submit
                    </LoadingButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </form>
          </ModalDialog>
        )}
      </Box>

      {isAddRMLoading && <Loader key="add_rm" />}

      {!isAddRMLoading && isAddRMFailed && (
        <SnackBarBox
          type="error"
          message={handleError(addRMUserErrorContainer.errorMessage)}
        />
      )}

      {!isAddRMLoading && isAddRMSuccess && (
        <SnackBarBox type="success" message={NEW_RM_ADD_SUCCESS} />
      )}

      {isEditRMLoading && <Loader key="edit_rm" />}

      {!isEditRMLoading && isEditRMFailed && (
        <SnackBarBox
          type="error"
          message={handleError(editRMUserErrorContainer.errorMessage)}
        />
      )}

      {!isEditRMLoading && isEditRMSuccess && (
        <SnackBarBox type="success" message={NEW_RM_EDIT_SUCCESS} />
      )}

      {isTransferRMLoading && <Loader key="transfer_rm" />}

      {!isTransferRMLoading && isTransferRMFailed && (
        <SnackBarBox
          type="error"
          message={handleError(transferRMErrorContainer.errorMessage)}
        />
      )}

      {!isTransferRMLoading && isTransferRMSuccess && (
        <SnackBarBox type="success" message={RM_TRANSFERED_SUCCESS} />
      )}
    </StyledGrid>
  );
}

export default RMView;
