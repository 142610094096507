import { Box, styled } from '@mui/material';
import SideBar from '../containers/layout/SideBar';
import HeaderBar from '../containers/layout/HeaderBar';
import PropTypes from 'prop-types';
import MuiAppBar from '@mui/material/AppBar';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { setIsMenuOpen } from '../redux/reducers/menuSlice';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(1),
    ...(open && {
      // transition: theme.transitions.create('margin', {
      //   easing: theme.transitions.easing.easeOut,
      //   duration: theme.transitions.duration.enteringScreen
      // }),
      marginLeft: 0
    }),
    '@media (max-width: 600px)': {
      opacity: open ? 0.1 : 1
    }
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open'
})(({ theme, open }) => ({
  // transition: theme.transitions.create(['margin', 'width'], {
  //   easing: theme.transitions.easing.sharp,
  //   duration: theme.transitions.duration.leavingScreen
  // }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`
    // transition: theme.transitions.create(['margin', 'width'], {
    //   easing: theme.transitions.easing.easeOut,
    //   duration: theme.transitions.duration.enteringScreen
    // })
  })
}));

const Layout = ({ children, title = '', menulist = [], module = '' }) => {
  const dispatch = useDispatch();

  const [isScreenSmallerThaniPad, setIsScreenSmallerThaniPad] = useState(false);

  useEffect(() => {
    function handleResize() {
      const iPadWidth = 768;
      setIsScreenSmallerThaniPad(window.innerWidth < iPadWidth);
    }

    handleResize();

    window.addEventListener('resize', handleResize);

    if (isScreenSmallerThaniPad) {
      dispatch(setIsMenuOpen(false));
    } else {
      dispatch(setIsMenuOpen(true));
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { isMenuOpen } = useSelector(state => state.menu);

  return (
    <Box
      sx={{
        backgroundColor: theme =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[200]
            : theme.palette.grey[900],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto'
      }}
    >
      <AppBar
        sx={{
          backgroundColor: theme =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[200]
              : theme.palette.grey[900]
        }}
        elevation={0}
        position="static"
        open={isMenuOpen}
      >
        <HeaderBar title={title} />
        <SideBar menulist={menulist} heading={module} />
        <Main open={isMenuOpen}>{children}</Main>
      </AppBar>
    </Box>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  menulist: PropTypes.array,
  module: PropTypes.string
};

export default Layout;
