import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip
} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import SearchIcon from '@mui/icons-material/Search';
import { BRANCHES_SEARCH_CRITERIA_LIST } from '../../../../../utils/constant';
import { isArrayNotEmpty } from '../../../../../utils/utils';
import GenericInput from '../../../../../components/GenericInput';

function BranchesFilter({ onFilterSubmit }) {
  const formik = useFormik({
    initialValues: {
      searchCriteria: '',
      searchField: '',
      startDate: null,
      endDate: null
    },
    validationSchema: yup.object({
      startDate: yup
        .date()
        .nullable()
        .test(
          'startDate',
          'Start Date is required when End Date is selected',
          function (value) {
            const endDate = this.parent.endDate;
            return !endDate || !!value;
          }
        )
        .test(
          'startDate',
          'Start Date cannot be larger than End Date',
          function (value) {
            const endDate = this.parent.endDate;
            return !endDate || !value || new Date(value) <= new Date(endDate);
          }
        ),
      endDate: yup
        .date()
        .nullable()
        .test(
          'endDate',
          'End Date is required when Start Date is selected',
          function (value) {
            const startDate = this.parent.startDate;
            return !startDate || !!value;
          }
        )
    }),
    onSubmit: values => {
      onFilterSubmit(values);
    }
  });

  const resetFilter = () => {
    formik.resetForm();
    onFilterSubmit({
      searchCriteria: '',
      caseCategory: '',
      searchField: '',
      startDate: null,
      endDate: null
    });
  };

  const handleStartDate = value => {
    formik.setFieldValue('startDate', value, true);
    formik.setFieldValue('endDate', '', true);
  };

  const handleEndDate = value => {
    formik.setFieldValue('endDate', value, true);
  };

  return (
    <form autoComplete="off" onSubmit={formik.handleSubmit}>
      <Grid
        container
        direction="row"
        spacing={2}
        sx={{ paddingLeft: 3, paddingRight: 3 }}
      >
        <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
          <FormControl fullWidth size="small">
            <InputLabel id="search-criteria">Search Criteria</InputLabel>
            <Select
              labelId="search-criteria"
              id="search-criteria"
              label="Search Criteria"
              value={formik.values.searchCriteria}
              onChange={formik.handleChange}
              name="searchCriteria"
              size="small"
            >
              {isArrayNotEmpty(BRANCHES_SEARCH_CRITERIA_LIST) &&
                BRANCHES_SEARCH_CRITERIA_LIST.map(searchC => {
                  return (
                    <MenuItem key={searchC?.key} value={searchC?.key}>
                      <em style={{ fontStyle: 'normal' }}>{searchC?.value}</em>
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          {formik.touched.searchCriteria && formik.errors.searchCriteria && (
            <FormHelperText sx={{ textAlign: 'start', fontSize: 10 }}>
              {formik.errors.searchCriteria}
            </FormHelperText>
          )}
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
          <FormControl variant="outlined" fullWidth size="small">
            <GenericInput
              label="Search "
              name="searchField"
              size="small"
              value={formik.values.searchField}
              onChange={formik.handleChange}
              startIcon={<SearchIcon />}
            />
          </FormControl>

          {formik.touched.searchField && formik.errors.searchField && (
            <FormHelperText sx={{ textAlign: 'start', fontSize: 10 }}>
              {formik.errors.searchField}
            </FormHelperText>
          )}
        </Grid>

        {/* <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{ width: '100%' }}
              label="Start date"
              disableFuture
              slotProps={{ textField: { size: 'small', readOnly: true } }}
              format="DD/MM/YYYY"
              name="startDate"
              onChange={value => handleStartDate(value)}
              value={formik.values.startDate || null}
              textField={props => (
                <TextField
                  {...props}
                  label="Start date"
                  margin="normal"
                  name="startDate"
                  variant="standard"
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
          {formik.touched.startDate && formik.errors.startDate && (
            <FormHelperText sx={{ textAlign: 'start', fontSize: 10 }}>
              {formik.errors.startDate}
            </FormHelperText>
          )}
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={2} xl={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              sx={{ width: '100%' }}
              label="End date"
              disableFuture
              slotProps={{ textField: { size: 'small', readOnly: true } }}
              format="DD/MM/YYYY"
              name="endDate"
              onChange={value => handleEndDate(value)}
              value={formik.values.endDate || null}
              textField={props => (
                <TextField
                  {...props}
                  label="End date"
                  margin="normal"
                  name="endDate"
                  variant="standard"
                  fullWidth
                />
              )}
            />
            {formik.touched.endDate && formik.errors.endDate && (
              <FormHelperText sx={{ textAlign: 'start', fontSize: 10 }}>
                {formik.errors.endDate}
              </FormHelperText>
            )}
          </LocalizationProvider>
        </Grid> */}

        <Grid item xs={12} sm={6} md={2} lg={1} xl={1}>
          <Tooltip placement="top" title="Search">
            <LoadingButton
              fullWidth
              style={{ textTransform: 'uppercase', height: 40 }}
              variant="contained"
              color="primary"
              type="submit"
            >
              Search
            </LoadingButton>
          </Tooltip>
        </Grid>

        <Grid item xs={12} sm={6} md={2} lg={1} xl={1}>
          <Tooltip placement="top" title="Reset">
            <LoadingButton
              fullWidth
              style={{
                textTransform: 'uppercase',
                height: 40
              }}
              variant="contained"
              color="primary"
              onClick={() => resetFilter()}
            >
              Reset
            </LoadingButton>
          </Tooltip>
        </Grid>
      </Grid>
    </form>
  );
}

export default BranchesFilter;
