import {
  Box,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Tooltip
} from '@mui/material';
import {
  FormButtonBottom,
  StyledGrid
} from '../../../../../utils/styledLayout';
import CustomDataTable from '../../../../../components/CustomDataTable';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addNewBranch,
  clearBranchNotification,
  editBranch,
  fetchBranches,
  fetchToBranchList,
  submitTransferBranch
} from '../../../../../redux/reducers/adminSlice';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SendIcon from '@mui/icons-material/Send';
import { LoadingButton } from '@mui/lab';
import {
  createTitle,
  enterOnlyAlphabates,
  enterOnlyEmail,
  enterOnlyNumbers,
  formatDate,
  formatINR,
  handleError,
  isArrayNotEmpty,
  isValidEmail,
  isValidMobileNumber
} from '../../../../../utils/utils';
import ModalDialog from '../../../../../components/ModalDialog';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { colorGolden } from '../../../../../config/theme';
import GenericAutocomplete from '../../../../../components/GenericAutocomplete';
import {
  BRANCH_TRANSFERED_SUCCESS,
  CANCEL_BUTTON_MESSAGE,
  NEW_BRANCH_ADD_SUCCESS,
  NEW_BRANCH_EDIT_SUCCESS,
  SUBMIT_BUTTON_MESSAGE
} from '../../../../../utils/messages';
import EditIcon from '@mui/icons-material/Edit';
import Loader from '../../../../../components/Loader';
import SnackBarBox from '../../../../../components/SnackBarBox';
import BranchesFilter from './BranchesFilter';
import { setActiveMenu } from '../../../../../redux/reducers/menuSlice';

const formControl = {
  margin: '0.5rem auto'
};

function Branches() {
  const dispatch = useDispatch();

  const findById = (array, id) => array.find(item => item.id === id);

  const findByValue = (array, value) =>
    array.find(item => item.value === value);

  const [branchList, setBranchList] = useState([]);

  const [pageData, setPageData] = useState({
    page: 0,
    size: 5,
    branchName: '',
    fromDate: '',
    toDate: '',
    phoneNo: '',
    location: '',
    email: '',
    targetAmount: ''
  });

  const {
    toBranchList,
    branchDetails,
    isFetchBranchesLoading,
    isAddBranchLoading,
    isAddBranchSuccess,
    isAddBranchFailed,
    addBranchErrorContainer,
    isEditBranchLoading,
    isEditBranchSuccess,
    isEditBranchFailed,
    editBranchErrorContainer,
    isTransferBranchLoading,
    isTransferBranchSuccess,
    isTransferBranchFailed,
    transferBranchErrorContainer
  } = useSelector(state => state.admin);

  useEffect(() => {
    dispatch(fetchBranches(pageData));
  }, [pageData]);

  useEffect(() => {
    document.title = createTitle('Business Teams');
    dispatch(fetchToBranchList());
    dispatch(setActiveMenu('Branches'));
    return () => dispatch(clearBranchNotification());
  }, []);

  useEffect(() => {
    if (isArrayNotEmpty(toBranchList)) {
      const keysArray = toBranchList.map(item => item.value);
      setBranchList(keysArray);
    }
  }, [toBranchList]);

  const headersList = [
    {
      uniquekey: 'id',
      id: 'branchName',
      label: 'Branch Name',
      sortable: true
    },
    { id: 'location', label: 'Location', sortable: true },
    {
      id: 'phoneNo',
      label: 'Phone Number',
      sortable: true
    },
    {
      id: 'email',
      label: 'Email ID',
      sortable: true
    },
    {
      id: 'targetAmount',
      label: 'Target Amount',
      sortable: true,
      render: row => {
        return <>{formatINR(row['targetAmount'])}</>;
      }
    },
    {
      id: 'businessName',
      label: 'Business Name',
      sortable: true
    },
    {
      id: 'action',
      label: 'Action',
      sortable: true,
      render: row => {
        return (
          <>
            <Tooltip placement="top" title="Edit">
              <IconButton
                aria-label="more"
                id="long-button"
                onClick={() => handleButtonClick(row)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      }
    }
  ];

  const [selectedValues, setSelectedValues] = useState(null);

  const [isShowTransferModal, setIsShowTransferModal] = useState(false);

  const [isShowAddModal, setIsShowAddModal] = useState(false);

  const [isShowEditModal, setIsShowEditModal] = useState(false);

  const [currentSelectedBranch, setCurrentSelectedBranch] = useState({});

  useEffect(() => {
    if (!isAddBranchLoading && isAddBranchSuccess) {
      handleCloseAddBranch();
      dispatch(fetchBranches(pageData));
      dispatch(fetchToBranchList());
    }
  }, [isAddBranchSuccess]);

  useEffect(() => {
    if (!isEditBranchLoading && isEditBranchSuccess) {
      handleCloseEditBranch();
      dispatch(fetchBranches(pageData));
      dispatch(fetchToBranchList());
    }
  }, [isEditBranchSuccess]);

  useEffect(() => {
    if (!isTransferBranchLoading && isTransferBranchSuccess) {
      handleCancelTransferClick();
      dispatch(fetchBranches(pageData));
    }
  }, [isTransferBranchSuccess]);

  const transferBranchFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      branchName: currentSelectedBranch?.branchName || '',
      transferToBranch: '',
      reason: ''
    },
    validationSchema: yup.object({
      branchName: yup.string().required('Transfer from branch is required'),
      transferToBranch: yup.string().required('Transfer to branch is required'),
      reason: yup.string().required('Reason for transfer branch is required')
    }),
    onSubmit: value => {
      const newValues = {
        fromBranchId: currentSelectedBranch?.id,
        toBranchId: value?.transferToBranch,
        transferReason: value?.reason.trim()
      };
      dispatch(submitTransferBranch(newValues));
    }
  });

  const addBranchFormik = useFormik({
    initialValues: {
      branchName: '',
      location: '',
      phoneNo: '',
      email: '',
      targetAmount: ''
    },
    validationSchema: yup.object({
      branchName: yup.string().required('Branch name is required'),
      location: yup.string().required('Location is required'),
      phoneNo: yup
        .string()
        .test(
          'is-valid-mobile-number',
          'Phone number should consist of exactly ten digits.',
          value => isValidMobileNumber(value)
        )
        .required('Phone Number is required'),
      email: yup
        .string()
        .test(
          'is-valid-email',
          'Please provide a valid email address.',
          value => isValidEmail(value)
        )
        .required('Email ID is required'),
      targetAmount: yup.string().required('Target amount is required')
    }),
    onSubmit: values => {
      dispatch(addNewBranch(values));
    }
  });

  const editBranchFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      branchName: currentSelectedBranch?.branchName || '',
      location: currentSelectedBranch?.location || '',
      phoneNo: currentSelectedBranch?.phoneNo || '',
      email: currentSelectedBranch?.email || '',
      targetAmount: currentSelectedBranch?.targetAmount || ''
    },
    validationSchema: yup.object({
      branchName: yup.string().required('Branch Name is required'),
      location: yup.string().required('Location is required'),
      phoneNo: yup
        .string()
        .test(
          'is-valid-mobile-number',
          'Phone number should consist of exactly ten digits.',
          value => isValidMobileNumber(value)
        )
        .required('Phone Number is required'),
      email: yup
        .string()
        .test(
          'is-valid-email',
          'Please provide a valid email address.',
          value => isValidEmail(value)
        )
        .required('Email ID is required'),
      targetAmount: yup.string().required('Target amount is required')
    }),
    onSubmit: values => {
      dispatch(
        editBranch({
          branchDetails: values,
          branchId: currentSelectedBranch?.id
        })
      );
    }
  });

  const onPageChange = page => {
    setPageData({ ...pageData, page: page });
  };
  const onRowsPerPageChange = page => {
    setPageData({ ...pageData, size: page });
  };

  const onSelectionChange = value => {
    setSelectedValues(value);
  };

  const handleTransferClick = () => {
    const branchesDetails = branchDetails?.content;
    const branch = findById(branchesDetails, selectedValues[0]);
    setCurrentSelectedBranch(branch);
    setIsShowTransferModal(!isShowTransferModal);
  };

  const handleCancelTransferClick = () => {
    setIsShowTransferModal(false);
    setCurrentSelectedBranch({});
    transferBranchFormik.resetForm();
  };

  const handleOpenAddBranch = () => {
    setIsShowAddModal(!isShowAddModal);
  };

  const handleCloseAddBranch = () => {
    setIsShowAddModal(false);
    setCurrentSelectedBranch({});
    addBranchFormik.resetForm();
  };
  const handleCloseEditBranch = () => {
    setIsShowEditModal(false);
    setCurrentSelectedBranch({});
    editBranchFormik.resetForm();
  };

  const handleButtonClick = row => {
    setCurrentSelectedBranch(row);
    setIsShowEditModal(!isShowEditModal);
  };

  const onFilterSubmit = value => {
    setPageData({
      ...pageData,
      page: 0,
      branchName:
        value.searchCriteria === 'branchName' ? value.searchField : '',
      fromDate: value.startDate
        ? formatDate(value.startDate, 'YYYY-MM-DD')
        : '',
      toDate: value.endDate ? formatDate(value.endDate, 'YYYY-MM-DD') : '',
      location: value.searchCriteria === 'location' ? value.searchField : '',
      phoneNo: value.searchCriteria === 'phoneNo' ? value.searchField : '',
      email: value.searchCriteria === 'email' ? value.searchField : '',
      targetAmount:
        value.searchCriteria === 'targetAmount' ? value.searchField : ''
    });
  };

  const onAutoCompleteChange = value => {
    const rmUser = findByValue(toBranchList, value);
    const rmKey = rmUser?.key;
    transferBranchFormik.setFieldValue('transferToBranch', rmKey);
  };

  return (
    <StyledGrid
      container
      direction="row"
      justifyContent="left"
      alignItems="left"
    >
      <Box
        sx={{
          borderRadius: 5,
          backgroundColor: 'white',
          width: '100%',
          boxShadow: '0px 1px 17px -5px rgba(0,0,0,0.67)',
          paddingLeft: 1,
          paddingRight: 1
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={3}
          paddingRight={2}
          paddingTop={2}
          paddingBottom={2}
        >
          <Grid item>
            <Tooltip placement="top" title="Add Branch">
              <IconButton
                onClick={handleOpenAddBranch}
                aria-label="add"
                size="large"
                className={isShowAddModal ? '' : 'button-glow'}
              >
                <AddCircleIcon sx={{ color: colorGolden }} fontSize="inherit" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        <Grid paddingTop={2} paddingBottom={2}>
          <Grid item>
            <BranchesFilter onFilterSubmit={onFilterSubmit} />
          </Grid>
        </Grid>

        <Grid container direction="row" sx={{ marginTop: 1 }}>
          <CustomDataTable
            columns={headersList}
            data={branchDetails['content']}
            headStyle={{ backgroundColor: colorGolden, color: 'black' }}
            totalCount={branchDetails['totalElements']}
            defaultRowsPerPage={5}
            defaultPage={pageData?.page}
            onRowsPerPageChange={onRowsPerPageChange}
            onPageChange={onPageChange}
            isCursor={false}
            allowSelection={true}
            onSelectionChange={onSelectionChange}
            selectedRows={selectedValues}
            isLoading={isFetchBranchesLoading}
            checkType="radio"
          />
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          marginTop={2}
          marginBottom={2}
        >
          <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
            <LoadingButton
              endIcon={<SendIcon />}
              fullWidth
              style={{ textTransform: 'uppercase' }}
              variant="contained"
              color="primary"
              onClick={handleTransferClick}
              disabled={Boolean(!isArrayNotEmpty(selectedValues))}
            >
              Transfer
            </LoadingButton>
          </Grid>
        </Grid>

        {isShowTransferModal && (
          <ModalDialog fullwidth={true} isShowTitle={true} titleName="TRANSFER">
            <form
              autoComplete="off"
              onSubmit={transferBranchFormik.handleSubmit}
            >
              <Grid container spacing={1} mt={2}>
                <FormControl
                  variant="outlined"
                  sx={{ ...formControl }}
                  fullWidth
                >
                  <TextField
                    label="Transfer from branch"
                    required
                    name="branchName"
                    type="text"
                    inputProps={{ maxLength: 60, readOnly: true }}
                    size="small"
                    value={transferBranchFormik.values.branchName}
                    onBlur={transferBranchFormik.handleBlur}
                    error={Boolean(
                      transferBranchFormik.touched.transferFromBranch &&
                        transferBranchFormik.errors.transferFromBranch
                    )}
                    helperText={
                      transferBranchFormik.touched.transferFromBranch &&
                      transferBranchFormik.errors.transferFromBranch
                    }
                  />
                </FormControl>

                <FormControl
                  variant="outlined"
                  sx={{ ...formControl }}
                  fullWidth
                >
                  <GenericAutocomplete
                    options={branchList}
                    onSelect={onAutoCompleteChange}
                    name="transferToBranch"
                    label="Transfer to branch"
                    onBlur={transferBranchFormik.handleChange}
                    error={Boolean(
                      transferBranchFormik.touched.transferToBranch &&
                        transferBranchFormik.errors.transferToBranch
                    )}
                    helperText={
                      transferBranchFormik.touched.transferToBranch &&
                      transferBranchFormik.errors.transferToBranch
                    }
                  />
                </FormControl>

                <FormControl
                  variant="outlined"
                  sx={{ ...formControl }}
                  fullWidth
                >
                  <TextField
                    label="Reason for transfer of branch"
                    required
                    name="reason"
                    type="text"
                    multiline
                    rows="2"
                    inputProps={{ maxLength: 255 }}
                    size="small"
                    onChange={e =>
                      transferBranchFormik.setFieldValue(
                        'reason',
                        enterOnlyAlphabates(e.target.value)
                      )
                    }
                    value={transferBranchFormik.values.reason}
                    onBlur={transferBranchFormik.handleBlur}
                    error={Boolean(
                      transferBranchFormik.touched.reason &&
                        transferBranchFormik.errors.reason
                    )}
                    helperText={
                      transferBranchFormik.touched.reason &&
                      transferBranchFormik.errors.reason
                    }
                  />
                </FormControl>
              </Grid>

              <FormButtonBottom sx={formControl}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <LoadingButton
                      type="button"
                      fullWidth
                      variant="outlined"
                      size="large"
                      color="primary"
                      sx={{
                        fontWeight: '600',
                        textTransform: 'uppercase'
                      }}
                      onClick={handleCancelTransferClick}
                    >
                      Cancel
                    </LoadingButton>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <LoadingButton
                      type="submit"
                      fullWidth
                      variant="contained"
                      size="large"
                      color="primary"
                      sx={{
                        fontWeight: '600',
                        textTransform: 'uppercase'
                      }}
                    >
                      Transfer
                    </LoadingButton>
                  </Grid>
                </Grid>
              </FormButtonBottom>
            </form>
          </ModalDialog>
        )}

        {isShowAddModal && (
          <ModalDialog
            fullwidth={true}
            isShowTitle={true}
            titleName="ADD BRANCH"
            size="sm"
          >
            <form autoComplete="off" onSubmit={addBranchFormik.handleSubmit}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                marginTop={1}
              >
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormControl
                    variant="outlined"
                    sx={{ ...formControl }}
                    fullWidth
                  >
                    <TextField
                      label="Branch Name"
                      required
                      name="branchName"
                      type="text"
                      inputProps={{ maxLength: 60 }}
                      size="small"
                      value={addBranchFormik.values.branchName}
                      onChange={e => {
                        addBranchFormik.setFieldValue(
                          'branchName',
                          enterOnlyAlphabates(e.target.value)
                        );
                      }}
                      onBlur={addBranchFormik.handleBlur}
                      error={Boolean(
                        addBranchFormik.touched.branchName &&
                          addBranchFormik.errors.branchName
                      )}
                      helperText={
                        addBranchFormik.touched.branchName &&
                        addBranchFormik.errors.branchName
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormControl
                    variant="outlined"
                    sx={{ ...formControl }}
                    fullWidth
                  >
                    <TextField
                      label="Location"
                      required
                      name="location"
                      type="text"
                      inputProps={{ maxLength: 60 }}
                      size="small"
                      value={addBranchFormik.values.location}
                      onChange={e => {
                        addBranchFormik.setFieldValue(
                          'location',
                          enterOnlyAlphabates(e.target.value)
                        );
                      }}
                      onBlur={addBranchFormik.handleBlur}
                      error={Boolean(
                        addBranchFormik.touched.location &&
                          addBranchFormik.errors.location
                      )}
                      helperText={
                        addBranchFormik.touched.location &&
                        addBranchFormik.errors.location
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormControl
                    variant="outlined"
                    sx={{ ...formControl }}
                    fullWidth
                  >
                    <TextField
                      label="Phone Number"
                      required
                      name="phoneNo"
                      type="tel"
                      inputProps={{ maxLength: 10 }}
                      size="small"
                      value={addBranchFormik.values.phoneNo}
                      onChange={e => {
                        addBranchFormik.setFieldValue(
                          'phoneNo',
                          enterOnlyNumbers(e.target.value)
                        );
                      }}
                      onBlur={addBranchFormik.handleBlur}
                      error={Boolean(
                        addBranchFormik.touched.phoneNo &&
                          addBranchFormik.errors.phoneNo
                      )}
                      helperText={
                        addBranchFormik.touched.phoneNo &&
                        addBranchFormik.errors.phoneNo
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormControl
                    variant="outlined"
                    sx={{ ...formControl }}
                    fullWidth
                  >
                    <TextField
                      label="Email ID"
                      required
                      name="email"
                      type="email"
                      inputProps={{ maxLength: 60 }}
                      size="small"
                      value={addBranchFormik.values.email}
                      onChange={e => {
                        addBranchFormik.setFieldValue(
                          'email',
                          enterOnlyEmail(e.target.value)
                        );
                      }}
                      onBlur={addBranchFormik.handleBlur}
                      error={Boolean(
                        addBranchFormik.touched.email &&
                          addBranchFormik.errors.email
                      )}
                      helperText={
                        addBranchFormik.touched.email &&
                        addBranchFormik.errors.email
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="left"
                alignItems="left"
                spacing={2}
              >
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormControl
                    variant="outlined"
                    sx={{ ...formControl }}
                    fullWidth
                  >
                    <TextField
                      label="Target Amount"
                      required
                      name="targetAmount"
                      type="tel"
                      inputProps={{ maxLength: 10 }}
                      size="small"
                      value={addBranchFormik.values.targetAmount}
                      onChange={e =>
                        addBranchFormik.setFieldValue(
                          'targetAmount',
                          enterOnlyNumbers(e.target.value)
                        )
                      }
                      onBlur={addBranchFormik.handleBlur}
                      error={Boolean(
                        addBranchFormik.touched.targetAmount &&
                          addBranchFormik.errors.targetAmount
                      )}
                      helperText={
                        addBranchFormik.touched.targetAmount &&
                        addBranchFormik.errors.targetAmount
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
                marginTop={2}
              >
                <Grid item xs={4} sm={4} md={4} lg={3} xl={3}>
                  <Tooltip placement="top" title={CANCEL_BUTTON_MESSAGE}>
                    <LoadingButton
                      type="button"
                      fullWidth
                      variant="outlined"
                      size="medium"
                      color="primary"
                      sx={{
                        fontWeight: '600',
                        textTransform: 'uppercase'
                      }}
                      onClick={handleCloseAddBranch}
                    >
                      Cancel
                    </LoadingButton>
                  </Tooltip>
                </Grid>

                <Grid item xs={4} sm={4} md={4} lg={3} xl={3}>
                  <Tooltip placement="top" title={SUBMIT_BUTTON_MESSAGE}>
                    <LoadingButton
                      type="submit"
                      fullWidth
                      variant="contained"
                      size="medium"
                      color="primary"
                      sx={{
                        fontWeight: '600',
                        textTransform: 'uppercase'
                      }}
                    >
                      Submit
                    </LoadingButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </form>
          </ModalDialog>
        )}

        {isShowEditModal && (
          <ModalDialog
            fullwidth={true}
            isShowTitle={true}
            titleName="EDIT BRANCH"
            size="sm"
          >
            <form autoComplete="off" onSubmit={editBranchFormik.handleSubmit}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                marginTop={1}
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <FormControl
                    variant="outlined"
                    sx={{ ...formControl }}
                    fullWidth
                  >
                    <TextField
                      label="Branch Name"
                      required
                      name="branchName"
                      type="text"
                      inputProps={{ maxLength: 60 }}
                      size="small"
                      value={editBranchFormik.values.branchName}
                      onChange={e => {
                        editBranchFormik.setFieldValue(
                          'branchName',
                          enterOnlyAlphabates(e.target.value)
                        );
                      }}
                      onBlur={editBranchFormik.handleBlur}
                      error={Boolean(
                        editBranchFormik.touched.branchName &&
                          editBranchFormik.errors.branchName
                      )}
                      helperText={
                        editBranchFormik.touched.branchName &&
                        editBranchFormik.errors.branchName
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormControl
                    variant="outlined"
                    sx={{ ...formControl }}
                    fullWidth
                  >
                    <TextField
                      label="Location"
                      required
                      name="location"
                      type="text"
                      inputProps={{ maxLength: 60 }}
                      size="small"
                      value={editBranchFormik.values.location}
                      onChange={e => {
                        editBranchFormik.setFieldValue(
                          'location',
                          enterOnlyAlphabates(e.target.value)
                        );
                      }}
                      onBlur={editBranchFormik.handleBlur}
                      error={Boolean(
                        editBranchFormik.touched.location &&
                          editBranchFormik.errors.location
                      )}
                      helperText={
                        editBranchFormik.touched.location &&
                        editBranchFormik.errors.location
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormControl
                    variant="outlined"
                    sx={{ ...formControl }}
                    fullWidth
                  >
                    <TextField
                      label="Phone Number"
                      required
                      name="phoneNo"
                      type="tel"
                      inputProps={{ maxLength: 10 }}
                      size="small"
                      value={editBranchFormik.values.phoneNo}
                      onChange={e => {
                        editBranchFormik.setFieldValue(
                          'phoneNo',
                          enterOnlyNumbers(e.target.value)
                        );
                      }}
                      onBlur={editBranchFormik.handleBlur}
                      error={Boolean(
                        editBranchFormik.touched.phoneNo &&
                          editBranchFormik.errors.phoneNo
                      )}
                      helperText={
                        editBranchFormik.touched.phoneNo &&
                        editBranchFormik.errors.phoneNo
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="left"
                alignItems="left"
                spacing={1}
              >
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormControl
                    variant="outlined"
                    sx={{ ...formControl }}
                    fullWidth
                  >
                    <TextField
                      label="Email ID"
                      required
                      name="email"
                      type="email"
                      inputProps={{ maxLength: 60 }}
                      size="small"
                      value={editBranchFormik.values.email}
                      onChange={e => {
                        editBranchFormik.setFieldValue(
                          'email',
                          enterOnlyEmail(e.target.value)
                        );
                      }}
                      onBlur={editBranchFormik.handleBlur}
                      error={Boolean(
                        editBranchFormik.touched.email &&
                          editBranchFormik.errors.email
                      )}
                      helperText={
                        editBranchFormik.touched.email &&
                        editBranchFormik.errors.email
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <FormControl
                    variant="outlined"
                    sx={{ ...formControl }}
                    fullWidth
                  >
                    <TextField
                      label="Target Amount"
                      required
                      name="targetAmount"
                      type="tel"
                      inputProps={{ maxLength: 10 }}
                      size="small"
                      value={editBranchFormik.values.targetAmount}
                      onChange={e =>
                        editBranchFormik.setFieldValue(
                          'targetAmount',
                          enterOnlyNumbers(e.target.value)
                        )
                      }
                      onBlur={editBranchFormik.handleBlur}
                      error={Boolean(
                        editBranchFormik.touched.targetAmount &&
                          editBranchFormik.errors.targetAmount
                      )}
                      helperText={
                        editBranchFormik.touched.targetAmount &&
                        editBranchFormik.errors.targetAmount
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
                marginTop={2}
              >
                <Grid item xs={4} sm={4} md={4} lg={3} xl={3}>
                  <Tooltip placement="top" title={CANCEL_BUTTON_MESSAGE}>
                    <LoadingButton
                      type="button"
                      fullWidth
                      variant="outlined"
                      size="medium"
                      color="primary"
                      sx={{
                        fontWeight: '600',
                        textTransform: 'uppercase'
                      }}
                      onClick={handleCloseEditBranch}
                    >
                      Cancel
                    </LoadingButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={3} xl={3}>
                  <Tooltip placement="top" title={SUBMIT_BUTTON_MESSAGE}>
                    <LoadingButton
                      type="submit"
                      fullWidth
                      variant="contained"
                      size="medium"
                      color="primary"
                      sx={{
                        fontWeight: '600',
                        textTransform: 'uppercase'
                      }}
                    >
                      Submit
                    </LoadingButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </form>
          </ModalDialog>
        )}
      </Box>

      {isAddBranchLoading && <Loader key="add_branch" />}

      {!isAddBranchLoading && isAddBranchFailed && (
        <SnackBarBox
          type="error"
          message={handleError(addBranchErrorContainer.errorMessage)}
        />
      )}

      {!isAddBranchLoading && isAddBranchSuccess && (
        <SnackBarBox type="success" message={NEW_BRANCH_ADD_SUCCESS} />
      )}

      {isEditBranchLoading && <Loader key="edit_branch" />}

      {!isEditBranchLoading && isEditBranchFailed && (
        <SnackBarBox
          type="error"
          message={handleError(editBranchErrorContainer.errorMessage)}
        />
      )}

      {!isEditBranchLoading && isEditBranchSuccess && (
        <SnackBarBox type="success" message={NEW_BRANCH_EDIT_SUCCESS} />
      )}

      {isTransferBranchLoading && <Loader key="transfer_branch" />}

      {!isTransferBranchLoading && isTransferBranchFailed && (
        <SnackBarBox
          type="error"
          message={handleError(transferBranchErrorContainer.errorMessage)}
        />
      )}

      {!isTransferBranchLoading && isTransferBranchSuccess && (
        <SnackBarBox type="success" message={BRANCH_TRANSFERED_SUCCESS} />
      )}
    </StyledGrid>
  );
}

export default Branches;
