import { useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import CategoryIcon from '@mui/icons-material/Category';
import { useDispatch } from 'react-redux';
import { moduleList } from '../../utils/constant';
import { authenticateUser } from '../../redux/reducers/authSlice';
import {
  enterOnlyAlphabatesOrNumber,
  enterWithNoSpaces,
  isArrayNotEmpty
} from '../../utils/utils';
import { colorGolden } from '../../config/theme';
import GenericInput from '../../components/GenericInput';
import { FormButtonBottom } from '../../utils/styledLayout';
import { SELECT_MODULE_MESSAGE } from '../../utils/messages';
import SnackBarBox from '../../components/SnackBarBox';

export const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(true);

  const [showError, setShowError] = useState(false);

  const formControl = {
    margin: '0.7rem auto'
  };

  const formik = useFormik({
    initialValues: {
      module: '',
      employeeId: '',
      password: ''
    },
    validationSchema: yup.object({
      module: yup.string().required('Module is required'),
      employeeId: yup.string().required('Employee Id is required'),
      password: yup.string().required('Password is required')
    }),
    onSubmit: value => {
      dispatch(authenticateUser(value));
    }
  });

  const handleFormValue = (e, field) => {
    formik.setFieldValue(
      field,
      enterOnlyAlphabatesOrNumber(e.target.value).toUpperCase()
    );
  };

  const handleResetPassword = () => {
    if (!formik.values.module) {
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 3000);
      return;
    }
    navigate(`/reset-password/${formik.values.module}`);
    return;
  };

  return (
    <Box sx={{ marginTop: '2rem' }}>
      <form autoComplete="off" onSubmit={formik.handleSubmit}>
        <FormControl sx={{ ...formControl }} fullWidth>
          <InputLabel id="select-module" required>
            Select Module
          </InputLabel>
          <Select
            displayEmpty
            required
            labelId="select-module"
            id="select-module"
            label="Select Module"
            size="small"
            value={formik.values.module || ''}
            onChange={formik.handleChange}
            name="module"
            placeholder="Module"
            sx={{ textAlign: 'left' }}
            startAdornment={
              <span
                style={{
                  padding: '0.5rem',
                  cursor: 'pointer',
                  display: 'inline-flex',
                  alignItems: 'center',
                  color: colorGolden
                }}
              >
                <CategoryIcon />
              </span>
            }
          >
            <MenuItem value="">
              <em style={{ fontStyle: 'normal' }}>Select Module</em>
            </MenuItem>

            {isArrayNotEmpty(moduleList) &&
              moduleList.map((type, _idx) => {
                return (
                  <MenuItem
                    key={`${_idx}_${type?.key}`}
                    value={type?.key}
                    disabled={type?.value === 'Admin'}
                  >
                    <em style={{ fontStyle: 'normal' }}>{type?.value}</em>
                  </MenuItem>
                );
              })}
          </Select>
          {formik.touched.module && formik.errors.module && (
            <FormHelperText sx={{ textAlign: 'start' }}>
              {formik.errors.module}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl variant="outlined" sx={formControl} fullWidth>
          <GenericInput
            required
            label="Employee ID"
            name="employeeId"
            size="small"
            inputProps={{ maxLength: 10 }}
            value={formik.values.employeeId}
            onChange={e => handleFormValue(e, 'employeeId')}
            error={formik.touched.employeeId && formik.errors.employeeId}
            startIcon={<PersonOutlineOutlinedIcon className="icon_color" />}
          />
        </FormControl>
        <FormControl variant="outlined" sx={formControl} fullWidth>
          <GenericInput
            required
            label="Password"
            size="small"
            name="password"
            type={!showPassword ? 'text' : 'password'}
            inputProps={{ maxLength: 16, minLength: 8 }}
            value={formik.values.password}
            error={formik.touched.password && formik.errors.password}
            startIcon={<LockOutlinedIcon className="icon_color" />}
            endIcon={
              showPassword ? (
                <VisibilityOffOutlinedIcon className="icon_color" />
              ) : (
                <VisibilityOutlinedIcon className="icon_color" />
              )
            }
            onEndIconClick={() => {
              setShowPassword(!showPassword);
            }}
            onChange={e =>
              formik.setFieldValue(
                'password',
                enterWithNoSpaces(e.target.value)
              )
            }
          />
        </FormControl>
        <Grid container>
          <Grid item textAlign={'end'} xs>
            <Button
              variant="text"
              style={{ fontWeight: '600' }}
              onClick={handleResetPassword}
              disabled={showError}
            >
              Reset Password
            </Button>
          </Grid>
        </Grid>
        <FormButtonBottom sx={formControl}>
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            size="medium"
            color="primary"
            sx={{
              mt: 2,
              fontSize: 15,
              fontWeight: '600',
              textTransform: 'uppercase'
            }}
          >
            Login
          </LoadingButton>
        </FormButtonBottom>
      </form>

      {showError && (
        <SnackBarBox type="error" message={SELECT_MODULE_MESSAGE} />
      )}
    </Box>
  );
};
