import { ADMIN_DASHBOARD, CREDIT_DASHBOARD } from '../utils/constant';
import CreditDashboard from '../containers/dashboard/credit/CreditDashboard';
import AdminDashboard from '../containers/dashboard/admin/AdminDashboard';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setActiveMenu } from '../redux/reducers/menuSlice';

export default function DashboardPage({ module }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActiveMenu('Dashboard'));
  }, []);

  return (
    <>
      {module === CREDIT_DASHBOARD && <CreditDashboard />}
      {module === ADMIN_DASHBOARD && <AdminDashboard />}
    </>
  );
}
