import { IconButton, Toolbar, Typography } from '@mui/material';
import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import PowerSettingsNewTwoToneIcon from '@mui/icons-material/PowerSettingsNewTwoTone';
import { useDispatch, useSelector } from 'react-redux';
import { setIsMenuOpen } from '../../redux/reducers/menuSlice';
import { useNavigate } from 'react-router-dom';
import { logoutAndClearToken } from '../../redux/reducers/authSlice';
import ConfirmBox from '../../components/ConfirmBox';
import PropTypes from 'prop-types';

function HeaderBar({ title = '' }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { isMenuOpen } = useSelector(state => state.menu);

  const handleChange = () => {
    dispatch(setIsMenuOpen(true));
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = () => {
    dispatch(logoutAndClearToken());
    navigate('/login');
    return;
  };

  return (
    <>
      <Toolbar>
        {!isMenuOpen && (
          <IconButton
            size="large"
            edge="start"
            color="primary"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => handleChange()}
          >
            <MenuIcon />
          </IconButton>
        )}

        <Typography
          variant="body1"
          fontWeight="700"
          fontSize={20}
          component="div"
          sx={{ flexGrow: 1 }}
        >
          {title}
        </Typography>
        <div>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => setIsModalOpen(true)}
            color="primary"
          >
            <PowerSettingsNewTwoToneIcon />
          </IconButton>
        </div>
      </Toolbar>
      {isModalOpen && (
        <ConfirmBox
          title="Logout"
          subtitle={`Are you sure you want to Logout?`}
          isOpen={isModalOpen}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
        />
      )}
    </>
  );
}
HeaderBar.propTypes = {
  title: PropTypes.string
};

export default HeaderBar;
