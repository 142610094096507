import * as Yup from 'yup';
import {
  BORROWER_IMAGE_EXTENSION,
  CaptureImageAllowedExtention,
  NET_WORTH_MANDATORY_LIMIT,
  UNEXPECTED_ERROR,
  UploadDocumentAllowedExtention,
  imageAllowedExtention
} from './constant';
import moment from 'moment';

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const emailRegExp =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const isEmail = value => {
  if (value && value.match(emailRegExp)) return true;
  else return false;
};
export const isMobile = value => {
  if (value && value.match(phoneRegExp)) return true;
  else return false;
};

export function matchIsNumeric(text) {
  const isNumber = typeof text === 'number';
  const isString = typeof text === 'string';
  return (isNumber || (isString && text !== '')) && !isNaN(Number(text));
}

export const validateChar = value => {
  return matchIsNumeric(value);
};

export const createTitle = titleName => {
  const updatedTitle = `UniLyfe | ${titleName}`;
  return updatedTitle;
};

export const getName = firstName => {
  return `${firstName || '<FirstName>'}`;
};

export const maskMobile = mobile => {
  const numberStr = mobile.toString();
  if (isNaN(mobile) || numberStr.length < 5) {
    return 'Invalid Number';
  }
  const firstDigits = numberStr.substring(0, 3);
  const lastDigits = numberStr.substring(numberStr.length - 2);
  const maskedNumber = `${firstDigits}XXXXX${lastDigits}`;
  return maskedNumber;
};

export const maskEmail = email => {
  const [localPart, domainPart] = email.split('@');
  const maskedLocalPart =
    localPart.length > 2
      ? localPart[0] + '*'.repeat(localPart.length - 2) + localPart.slice(-1)
      : localPart;
  const maskedEmail = `${maskedLocalPart}@${domainPart}`;
  return maskedEmail;
};

export const isAlphabet = value => /^[A-Za-z ]*$/.test(value);

export const isNumber = value => /^[0-9]*$/.test(value);

export const testPattern = (value, pattern, errorMessage) => {
  return Yup.string().matches(pattern, errorMessage).isValidSync(value);
};

export const isValidMobileNumber = value => {
  return testPattern(
    value,
    /^\d{10}$/,
    'The mobile number should consist of exactly 10 digits.'
  );
};

export const isValidEmail = value => {
  return testPattern(
    value,
    /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
    'Please enter a valid email address.'
  );
};

export const isValidPassword = value => {
  return testPattern(
    value,
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*[@$!%*?&].*$/,
    'Password requires 1 uppercase, 1 lowercase, 1 digit, and at least 1 special character.'
  );
};

export const isValidPinNumber = value => {
  return testPattern(
    value,
    /^[0-9]{4}$/,
    'The PIN should have exactly 4 digits.'
  );
};
export const isValidPanNumber = value => {
  return testPattern(
    value,
    /^[A-Z]{5}\d{4}[A-Z]$/,
    'The PAN Number should consist of exactly 10 digits.'
  );
};
export const isValidAadhaarNumber = value => {
  return testPattern(
    value,
    /^\d{12}$/,
    'The Aadhaar Number should be composed of exactly 12 digits.'
  );
};
export const isValidAddress = value => {
  return testPattern(value, /^[.,:\w\s-]+$/, 'Please provide a valid address.');
};
export const isValidPinCode = value => {
  return testPattern(value, /^\d{6}$/, 'Please enter a valid pincode.');
};

export const handleError = error => {
  return error ? error : UNEXPECTED_ERROR;
};

export const formatNumberWithCommas = number => {
  try {
    const value = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return value ? value : 0;
  } catch (error) {
    return 0;
  }
};

export const calculateMonthlyReturn = (principal, months) => {
  principal = Number(principal);
  if (
    isNaN(principal) ||
    isNaN(Number(process.env.REACT_APP_RATE_OF_INTEREST)) ||
    isNaN(months)
  ) {
    return 'Invalid input. Please provide valid numbers.';
  }
  const annualRate = Number(process.env.REACT_APP_RATE_OF_INTEREST) / 100;
  const monthlyRate = annualRate / 12;
  const monthlyInterest = principal * monthlyRate;
  const expectedReturn = principal + monthlyInterest * months;
  if (isNaN(expectedReturn)) {
    return 0;
  }
  return Number(expectedReturn).toFixed(2);
};

export const isArrayNotEmpty = array => {
  return Array.isArray(array) && array.length > 0;
};

export const formatDate = (date, format = 'YYYY-MM-DD') => {
  const formattedDate = moment(new Date(date)).format(format);
  return formattedDate === 'Invalid date' ? '-' : formattedDate;
};

export const enterOnlyAlphabates = value => {
  return value.replace(/[^A-Za-z\s]+|(?<=\s)\s+/g, '');
};
export const enterOnlyNumbers = value => {
  return value.replace(/[^0-9]/g, '');
};
export const enterOnlyEmail = value => {
  return value.replace(/[^a-zA-Z@.^0-9]/g, '');
};
export const enterEmailOrMobile = value => {
  return value.replace(/[^0-9a-zA-Z@.+\\-]/g, '');
};

export const enterWithNoSpaces = value => {
  return value.replace(/\s/g, '');
};
export const enterMaxInvestedAmount = value => {
  value = value.trim().replace(/^0+/, '');
  const sanitizedValue = enterOnlyNumbers(value);
  const numericValue = parseFloat(sanitizedValue);

  if (numericValue < 0) {
    return 0;
  }
  return sanitizedValue;
};

export const enterOnlyAlphabetsAndNumbers = value => {
  return value.replace(/[^a-zA-Z0-9]/g, '');
};

export const convertToDatePicker = inputDateString => {
  var dateFormats = [
    'YYYY-MM-DD',
    'MM/DD/YYYY',
    'DD/MM/YYYY',
    'DD-MM-YYYY',
    'YYYY/MM/DD'
  ];

  for (var i = 0; i < dateFormats.length; i++) {
    var parsedDate = moment(inputDateString, dateFormats[i], true);
    if (parsedDate.isValid()) {
      return parsedDate.format('YYYY-MM-DD');
    }
  }
  return inputDateString;
};

export const printCurrentTimeStamp = () => {
  const currentDate = moment();
  const formattedDate = currentDate.format('DD MMMM, YYYY | h:mm A');
  return formattedDate;
};

export const isGreaterThanZero = value => {
  if (typeof value === 'number') {
    return value > 0;
  }
  return false;
};

export const isImageExtensionAllowed = filename => {
  const allowedExtensions = imageAllowedExtention;
  const fileExtension = filename.split('.').pop().toLowerCase();
  return allowedExtensions.includes(fileExtension);
};
export const isBorrowerImageExtensionAllowed = filename => {
  const allowedExtensions = BORROWER_IMAGE_EXTENSION;
  const fileExtension = filename.split('.').pop().toLowerCase();
  return allowedExtensions.includes(fileExtension);
};
export const isUploadImageExtensionAllowed = filename => {
  const allowedExtensions = UploadDocumentAllowedExtention;
  const fileExtension = filename.split('.').pop().toLowerCase();
  return allowedExtensions.includes(fileExtension);
};
export const isCaptureImageExtensionAllowed = filename => {
  const allowedExtensions = CaptureImageAllowedExtention;
  const fileExtension = filename.split('.').pop().toLowerCase();
  return allowedExtensions.includes(fileExtension);
};

export const getImageDetails = (docType, documents) => {
  if (isArrayNotEmpty(documents)) {
    const document = documents.find(
      document => document.documentType == docType
    );
    return document ? document : null;
  }
};

export function categorizeDocuments(data) {
  const categorizedData = {};

  data.forEach(item => {
    const { documentType } = item;

    if (!categorizedData[documentType]) {
      categorizedData[documentType] = {};
    }

    categorizedData[documentType] = {
      ...categorizedData[documentType],
      ...item
    };
  });

  return categorizedData;
}

export const getFileName = content => {
  console.log('content ', content);
  try {
    let filename = '';
    const filenameMatch = /filename=([^;\s]+)/.exec(content);
    if (filenameMatch) {
      filename = filenameMatch[1];
    }
    filename = filename.replace(/"/g, '');
    return filename;
  } catch (error) {
    console.error(error);
  }
};

export const isValidOtp = value => {
  return testPattern(value, /^\d{4}$/, 'Please enter a valid OTP.');
};

export const highlightText = (text, query) => {
  if (!query) return text;
  const regex = new RegExp(`(${query})`, 'gi');
  return text.split(regex).map((part, index) =>
    regex.test(part) ? (
      <span key={index} style={{ backgroundColor: 'yellow' }}>
        {part}
      </span>
    ) : (
      part
    )
  );
};

export const maskAccountNumber = inputString => {
  try {
    if (inputString.length < 4) {
      return 'XXXX' + inputString.slice(-4);
    }
    const maskedCharacters = 'X'.repeat(inputString.length - 4);
    const lastFourDigits = inputString.slice(-4);
    const maskedString = `${maskedCharacters}${lastFourDigits}`;
    return maskedString;
  } catch (error) {
    console.error(error);
  }
};

export const isDisplayNetworthCertificate = (
  currentInvestment,
  previousInvestment
) => {
  try {
    currentInvestment = Number(currentInvestment);
    previousInvestment = Number(previousInvestment);

    const totalInvestment = currentInvestment + previousInvestment;

    return totalInvestment >= Number(NET_WORTH_MANDATORY_LIMIT);
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getFullName = (firstName, middleName, lastName) => {
  const nameParts = [];
  if (firstName && firstName.trim() !== '') {
    nameParts.push(firstName);
  }
  if (middleName && middleName.trim() !== '') {
    nameParts.push(middleName);
  }
  if (lastName && lastName.trim() !== '') {
    nameParts.push(lastName);
  }
  const fullName = nameParts.join(' ');
  return fullName;
};

export const generateImageSrc = (base64String, mimeType = 'image') => {
  try {
    if (!base64String || typeof base64String !== 'string') {
      return null;
    }
    return `data:${mimeType};base64,${base64String}`;
  } catch (error) {
    console.error(error);
  }
};

export const formatAmountInINR = amount => {
  return '₹ ' + new Intl.NumberFormat('en-IN').format(amount);
};

export const isEmptyString = str => {
  return str.trim().length === 0;
};

export const formatDateTimeStamp = date => {
  const currentDate = moment(date);
  // const modifiedDate = currentDate.add(5, 'hours').add(30, 'minutes');
  const formattedDate = currentDate.format('DD MMMM, YYYY | h:mm A');
  return formattedDate;
};

export const parseParams = (params = '') => {
  const rawParams = params.replace('?', '').split('&');
  const extractedParams = {};
  rawParams.forEach(item => {
    item = item.split('=');
    extractedParams[item[0]] = item[1];
  });
  return extractedParams;
};

export const formatINR = amount => {
  if (!amount) return `-`;
  return `₹ ${new Intl.NumberFormat('en-IN').format(amount)}`;
};

export const generateArrayWithSteps = (totalCount, step) => {
  if (totalCount <= 0 || step <= 0) {
    return [];
  }
  const result = [];
  for (let i = step; i <= totalCount; i += step) {
    result.push(i);
  }
  return result;
};

export const printStringValue = val => {
  return val || val === 0 || val === '0' || val === 'null' ? val : '-';
};

export const MAXIMUM_LOAN_LIMIT = 1000000;
export const MINIMUM_LOAN_LIMIT = 10000;

export const calculateTotalLoanPayment = (principal, months, roi) => {
  const additionalFees = 0 / 100;

  principal = Number(principal);

  if (isNaN(principal) || isNaN(Number(roi)) || isNaN(months) || months <= 0) {
    return 0;
  }

  const emi =
    principal * (1 + (Number(roi) / 100 / 12) * months + additionalFees);

  return emi;
};

export const calculateMonthlyEMI = (principal, months, roi) => {
  if (isNaN(principal)) return 0;

  const monthlyEMI = calculateEMIbyNewFormula(principal, months, roi);
  return isNaN(monthlyEMI) ? 0 : parseFloat(monthlyEMI);
};

export const calculateEMIbyNewFormula = (
  loanAmount,
  tenure,
  ROI,
  decimalPlaces = 2
) => {
  const additionalFees = 0 / 100;
  const repaymentFrequency = 1;

  let emi =
    (loanAmount * (1 + (Number(ROI) / 100 / 12) * tenure + additionalFees)) /
    tenure /
    repaymentFrequency;
  emi = emi.toFixed(decimalPlaces);
  return parseFloat(emi);
};

export const toCamelCase = value => {
  if (typeof value === 'string') {
    const words = value.split(/\s+/);
    const capitalizedWords = words.map(word => {
      if (/^[a-zA-Z]+$/.test(word)) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      } else {
        return word;
      }
    });
    return capitalizedWords.join(' ');
  } else {
    return value;
  }
};

// export const calculateAmountTOBeApproved = (poolAmount, allocatedAmount, requestedAmount) => {
//   const totalAmountPayable = (
//     Number(poolAmount) - Number(allocatedAmount)
//   ).toFixed(2);
//   return isNaN(totalAmountPayable) ? 0 : parseFloat(totalAmountPayable);
// };

export const calculateAmountTOBeApproved = (
  poolAmount,
  allocatedAmount,
  requestedAmount
) => {
  const totalAmountPayable = (
    Number(poolAmount) - Number(allocatedAmount)
  ).toFixed(2);

  const amountToApprove = isNaN(totalAmountPayable)
    ? 0
    : parseFloat(totalAmountPayable);

  return Math.min(amountToApprove, requestedAmount);
};

export const truncateString = (str, maxLength = 20) => {
  return str.length > maxLength ? `${str.substring(0, maxLength)}...` : str;
};

export const checkValidScore = (score, type) => {
  const scoreLimit = 650;

  if (type === 'status') return score < scoreLimit;
  if (type === 'message' && score < scoreLimit)
    return `Minimum score should be ${scoreLimit}`;
};

export const checkNumberOf30PlusDpdIn3Months = (days, type) => {
  const daysLimit = 30;
  if (type === 'status') return days > daysLimit;
  if (type === 'message' && days > daysLimit)
    return `Days should maximum ${daysLimit}`;
};

export const checkNumberOf90PlusDpdIn12Months = (months, type) => {
  const monthLimit = 12;
  if (type === 'status') return months > monthLimit;
  if (type === 'message' && months > monthLimit)
    return `Months should maximum ${monthLimit}`;
};

export const checkTotalActiveAccounts = (account, type) => {
  const accountLimit = 5;
  if (type === 'status') return account > accountLimit;
  if (type === 'message' && account > accountLimit)
    return `Active accounts should maximum ${accountLimit}`;
};

export const checkNumberOfSpecialMentionedAccounts = (account, type) => {
  const accountLimit = 0;
  if (type === 'status') return account > accountLimit;
  if (type === 'message' && account > accountLimit)
    return `Special accounts should be ${accountLimit}`;
};

export const checkNumberOfSubStandardAccounts = (account, type) => {
  const accountLimit = 0;
  if (type === 'status') return account > accountLimit;
  if (type === 'message' && account > accountLimit)
    return `Sub standards accounts should be ${accountLimit}`;
};

export const checkNumberOfDoubtfullAccounts = (account, type) => {
  const accountLimit = 0;
  if (type === 'status') return account > accountLimit;
  if (type === 'message' && account > accountLimit)
    return `Doubtfull accounts should be ${accountLimit}`;
};

export const countBureauFlags = bureauResult => {
  const { data } = bureauResult;

  if (!data) return 0;

  const {
    score,
    totalActiveAccounts,
    numberOfSpecialMentionedAccounts,
    numberOfSubStandardAccounts,
    numberOfDoubtfullAccounts,
    numberOf30PlusDpdIn3Months,
    numberOf90PlusDpdIn12Months
  } = data;

  let count = 0;

  if (checkValidScore(score, 'status')) count++;
  if (checkNumberOf30PlusDpdIn3Months(numberOf30PlusDpdIn3Months, 'status'))
    count++;
  if (checkNumberOf90PlusDpdIn12Months(numberOf90PlusDpdIn12Months, 'status'))
    count++;
  if (checkTotalActiveAccounts(totalActiveAccounts, 'status')) count++;
  if (
    checkNumberOfSpecialMentionedAccounts(
      numberOfSpecialMentionedAccounts,
      'status'
    )
  )
    count++;
  if (checkNumberOfSubStandardAccounts(numberOfSubStandardAccounts, 'status'))
    count++;
  if (checkNumberOfDoubtfullAccounts(numberOfDoubtfullAccounts, 'status'))
    count++;

  return count;
};

export const isCorrectBureauDataIsAvailable = bureauResult => {
  return bureauResult?.score !== 'Score not found.';
};

export const enterOnlyAlphabatesOrNumber = value => {
  return value.replace(/[^A-Za-z0-9]/g, '');
};

export const returnStatusColor = status => {
  const basicCss = {
    fontWeight: '600',
    backgroundColor: 'white',
    width: '100%'
  };
  status = typeof status === 'string' ? status.toLowerCase() : '';
  switch (status) {
    case 'approved':
      return {
        ...basicCss,
        color: '#007f00', // Dark green
        border: '1px solid rgba(0, 127, 0, 0.6)', // Dark green border
        backgroundColor: 'rgba(255, 255, 255, 0.9)'
      };
    case 'pending':
      return {
        ...basicCss,
        color: '#cc7e0f', // Orange
        border: '1px solid rgba(204, 126, 15, 0.6)', // Orange border
        backgroundColor: 'rgba(255, 255, 255, 0.9)'
      };
    case 'rejected':
      return {
        ...basicCss,
        color: '#aa0000', // Dark red
        border: '1px solid rgba(170, 0, 0, 0.6)', // Dark red border
        backgroundColor: 'rgba(255, 255, 255, 0.9)'
      };
    case 'reopened':
      return {
        ...basicCss,
        color: '#6b1d9a', // Purplish
        border: '1px solid rgba(107, 29, 154, 0.6)', // Purplish border
        backgroundColor: 'rgba(255, 255, 255, 0.9)'
      };
    case 'unaccepted':
      return {
        ...basicCss,
        color: '#1c1f29', // Darker blue
        border: '1px solid rgba(28, 31, 41, 0.6)', // Darker blue border
        backgroundColor: 'rgba(255, 255, 255, 0.9)'
      };
    default:
      return {
        ...basicCss,
        color: 'black',
        border: '1px solid rgba(0, 0, 0, 0.3)',
        backgroundColor: 'rgba(255, 255, 255, 0.9)'
      };
  }
};
