import {
  Box,
  Chip,
  Collapse,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  SwipeableDrawer,
  Typography
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveMenu, setIsMenuOpen } from '../../redux/reducers/menuSlice';
import { IMG_SPLASH_LOGO } from '../../utils/imageUrls';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';
import { colorGolden } from '../../config/theme';

const drawerWidth = 250;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
}));

function SideBar({ menulist, heading }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { activeMenu, isMenuOpen } = useSelector(state => state.menu);

  const isActiveMenu = currentMenu => {
    return activeMenu === currentMenu;
  };

  const toggleDrawer = isOpen => event => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    // dispatch(setIsMenuOpen(isOpen));
  };

  const [open, setOpen] = useState({});

  const handleClick = (item, index, hasChild) => {
    if (hasChild) {
      setOpen(prevState => ({
        ...prevState,
        [index]: !prevState[index]
      }));
    } else {
      dispatch(setActiveMenu(item?.name));
      // dispatch(setIsMenuOpen(false));
      return navigate(item?.route);
    }
  };

  const renderSubmenus = submenus => {
    return (
      <>
        {submenus.map((submenu, index) => (
          <div key={`${submenu}_${index}`}>
            <ListItem
              disablePadding
              sx={{
                backgroundColor: isActiveMenu(submenu?.name)
                  ? '#e0e0e0'
                  : 'inherit',
                '&:hover': {
                  backgroundColor: '#f0f0f0'
                },
                fontWeight: isActiveMenu(submenu?.name) ? 'bold' : 'normal',
                borderRadius: '20px',
                marginLeft: '1rem',
                marginTop: '4px',
                marginBottom: '4px'
              }}
              key={index}
              component={Link}
              to={submenu.route}
            >
              <ListItemButton
                sx={{ borderRadius: '20px' }}
                onClick={() => handleClick(submenu, index)}
              >
                <Typography
                  fontWeight={isActiveMenu(submenu?.name) ? 700 : 500}
                  variant="subtitle2"
                >
                  {submenu.name}
                </Typography>
              </ListItemButton>
            </ListItem>
          </div>
        ))}
      </>
    );
  };

  const list = anchor => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 220 }}
      role="presentation"
      // onKeyDown={toggleDrawer(false)}
    >
      <Typography
        variant="subtitle1"
        sx={{
          marginLeft: 2,
          marginTop: 2,
          marginBottom: 1,
          fontWeight: 700
        }}
      >
        <Chip sx={{ color: colorGolden }} label={heading} variant="outlined" />
      </Typography>

      {menulist.map((item, index) => (
        <List
          key={`${item}_${index}`}
          component="div"
          sx={{ width: '100%', borderRadius: '20px', marginLeft: '5px' }}
        >
          <ListItem
            disablePadding
            sx={{
              backgroundColor: isActiveMenu(item?.name) ? '#e0e0e0' : 'inherit',
              '&:hover': {
                backgroundColor: '#f0f0f0'
              },
              fontWeight: isActiveMenu(item?.name) ? 'bold' : 'normal',
              borderRadius: '20px'
            }}
          >
            <ListItemButton
              sx={{ borderRadius: '20px' }}
              onClick={() => handleClick(item, index, item.hasChild)}
            >
              <Typography
                fontWeight={isActiveMenu(item?.name) ? 700 : 500}
                variant="subtitle2"
              >
                {item.name}
              </Typography>
              {item.hasChild && (open[index] ? <ExpandLess /> : <ExpandMore />)}
            </ListItemButton>
          </ListItem>
          {item.hasChild && (
            <Collapse in={open[index]} timeout="auto" unmountOnExit>
              {renderSubmenus(item.submenus)}
            </Collapse>
          )}
        </List>
      ))}
    </Box>
  );

  return (
    <>
      <SwipeableDrawer
        open={isMenuOpen}
        // onClose={toggleDrawer(false)}
        // onOpen={toggleDrawer(true)}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box'
          }
        }}
        variant="persistent"
        anchor="left"
      >
        <DrawerHeader>
          <Box role="presentation" sx={{ marginRight: 2 }}>
            <img
              style={{
                display: 'block',
                margin: 'auto',
                textAlign: 'center'
              }}
              width={180}
              src={IMG_SPLASH_LOGO}
              alt="IMG_SPLASH_LOGO"
            />
          </Box>
          <IconButton
            sx={{ borderRadius: '50%' }}
            // onClick={toggleDrawer(false)}
          >
            {/* <ChevronLeftIcon
              className="button-glow"
              sx={{ color: 'black', borderRadius: '50%' }}
            /> */}
          </IconButton>
        </DrawerHeader>
        <Divider />
        {list('left')}
      </SwipeableDrawer>
    </>
  );
}

SideBar.propTypes = {
  menulist: PropTypes.array,
  heading: PropTypes.string
};

export default SideBar;
