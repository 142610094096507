import { useFormik } from 'formik';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { submitRequestForResetPassword } from '../../../redux/reducers/authSlice';
import { Box, Container, FormControl } from '@mui/material';
import GenericInput from '../../../components/GenericInput';
import { enterWithNoSpaces } from '../../../utils/utils';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { LoadingButton } from '@mui/lab';
import ShowBackToLoginButton from './ShowBackToLoginButton';
import PropTypes from 'prop-types';

const formControl = {
  margin: '0.5rem auto'
};

function NewPasswordForm({ mobileNo }) {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);

  const { resetOtpDetails } = useSelector(state => state.auth);

  const passwordFormik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
      mobileNo: mobileNo
    },
    validationSchema: yup.object({
      password: yup
        .string()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
          'Password should include uppercase, lowercase, number, and special symbol.'
        )
        .min(8, 'New Password should be of minimum 8 digit')
        .max(16, 'New Password should be of maximum 16 digit')
        .required('New Password is required'),
      confirmPassword: yup
        .string()
        .required('Confirm Password is required')
        .oneOf(
          [yup.ref('password'), null],
          'Password and Confirm Password must match.'
        )
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: values => {
      const data = {
        mobileNo: values.mobileNo,
        password: values.password,
        confirmPassword: values.confirmPassword
      };
      dispatch(
        submitRequestForResetPassword({
          payload: data,
          token: resetOtpDetails?.resetToken
        })
      );
    }
  });

  return (
    <>
      <Container>
        <Box pt={2}>
          <form autoComplete="off" onSubmit={passwordFormik.handleSubmit}>
            <FormControl sx={formControl} variant="outlined" fullWidth>
              <GenericInput
                label="Password"
                name="password"
                size="small"
                required
                type={!showPassword ? 'text' : 'password'}
                inputProps={{ maxLength: 16 }}
                value={passwordFormik.values.password}
                onChange={e =>
                  passwordFormik.setFieldValue(
                    'password',
                    enterWithNoSpaces(e.target.value)
                  )
                }
                onBlur={passwordFormik.handleBlur}
                error={
                  passwordFormik.touched.password &&
                  passwordFormik.errors.password
                }
                startIcon={<LockOutlinedIcon className="icon_color" />}
                endIcon={
                  showPassword ? (
                    <VisibilityOffOutlinedIcon className="icon_color" />
                  ) : (
                    <VisibilityOutlinedIcon className="icon_color" />
                  )
                }
                onEndIconClick={() => {
                  setShowPassword(!showPassword);
                }}
              />
            </FormControl>
            <FormControl sx={formControl} variant="outlined" fullWidth>
              <GenericInput
                required
                label="Confirm Password"
                size="small"
                name="confirmPassword"
                type={!showConfirmPassword ? 'text' : 'password'}
                inputProps={{ maxLength: 16 }}
                value={passwordFormik.values.confirmPassword}
                onChange={e =>
                  passwordFormik.setFieldValue(
                    'confirmPassword',
                    enterWithNoSpaces(e.target.value)
                  )
                }
                onBlur={passwordFormik.handleBlur}
                error={
                  passwordFormik.touched.confirmPassword &&
                  passwordFormik.errors.confirmPassword
                }
                startIcon={<LockOutlinedIcon className="icon_color" />}
                endIcon={
                  showConfirmPassword ? (
                    <VisibilityOffOutlinedIcon className="icon_color" />
                  ) : (
                    <VisibilityOutlinedIcon className="icon_color" />
                  )
                }
                onEndIconClick={() => {
                  setShowConfirmPassword(!showConfirmPassword);
                }}
              />
            </FormControl>
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              size="medium"
              sx={{
                mt: 2,
                fontSize: 15,
                fontWeight: '600',
                textTransform: 'uppercase'
              }}
            >
              Submit
            </LoadingButton>
            <ShowBackToLoginButton />
          </form>
        </Box>
      </Container>
    </>
  );
}

NewPasswordForm.propTypes = {
  mobileNo: PropTypes.string
};

export default NewPasswordForm;
