import { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';

const GenericAutocomplete = ({
  label = '',
  error = false,
  helperText = '',
  options,
  defaultValue,
  onSelect,
  ...restProps
}) => {
  const [value, setValue] = useState(defaultValue || null);
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onSelect(newValue);
  };

  return (
    <>
      <Autocomplete
        fullWidth
        value={value}
        onChange={handleChange}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        id="generic-autocomplete"
        options={options}
        size="small"
        renderInput={params => <TextField {...params} label={label} />}
        {...restProps}
      />

      {error && (
        <FormHelperText sx={{ textAlign: 'start' }}>
          {helperText}
        </FormHelperText>
      )}
    </>
  );
};

export default GenericAutocomplete;
